import { gql } from "@apollo/client";
import { InviteToTipaltiCheckListItem_ExternalUserAccountFragment } from "@generated/graphql";
import { ChecklistItem } from "components/shared";
import { itemContentStyle } from "sections/Profile/constants";

InviteToTipaltiCheckListItem.fragments = {
  tipaltiAccount: gql`
    fragment InviteToTipaltiCheckListItem_ExternalUserAccount on ExternalUserAccount {
      type
      externalId
      isDisabled
      isDeleted
    }
  `,
};

type Props = {
  tipaltiAccount: InviteToTipaltiCheckListItem_ExternalUserAccountFragment | null;
};

export function InviteToTipaltiCheckListItem({ tipaltiAccount }: Props) {
  return (
    <ChecklistItem
      title="Wait for your payment account to be created"
      trackedForCompletion
      complete={!!tipaltiAccount}
      collapsibleContent={
        !!tipaltiAccount ? null : (
          <div className={itemContentStyle}>
            {`You’ll receive an invitation to set up your Tipalti Payment account
            within 5 days of confirming your TbT Portal account.`}
          </div>
        )
      }
    />
  );
}
