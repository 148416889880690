import { UserRacialIdentity } from "@generated/graphql";
import { userRacialIdentityOptions } from "@utils/selectMenuOptions";
import { useMemo } from "react";
import { SelectMenu, SelectMenuProps } from "../SelectMenu";

type Props = {
  className?: string;
  initialRacialIdentity?: UserRacialIdentity | null;
  selectMenuProps?: Exclude<SelectMenuProps, "initialIndex">;
  onSelect: (racialIdentity: UserRacialIdentity | null) => void;
};

export const RacialIdentitySelectMenu = ({
  className,
  selectMenuProps,
  initialRacialIdentity,
  onSelect,
}: Props) => {
  const initialIndex = useMemo(() => {
    return userRacialIdentityOptions.findIndex(
      ({ racialIdentity }) => racialIdentity === initialRacialIdentity
    );
  }, [initialRacialIdentity]);

  return (
    <SelectMenu
      labelText="Racial Identity"
      {...selectMenuProps}
      className={className}
      initialIndex={initialIndex}
      options={userRacialIdentityOptions}
      onSelect={({ racialIdentity }) => onSelect(racialIdentity)}
    />
  );
};
