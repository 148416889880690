import { Badge } from "./Badge";
import clsx from "clsx";

type Props = {
  className?: string;
};

export const ShiftBadge = ({ className }: Props) => {
  return (
    <Badge className={clsx("text-cyan-900 bg-cyan-200", className)}>
      Shift
    </Badge>
  );
};
