import { gql, useQuery } from "@apollo/client";
import { SetupPaymentsChecklistItemIsPaymentSetupQuery } from "@generated/graphql";
import { ChecklistItem, Icon, Link } from "components/shared";
import { useEffect, useState } from "react";
import {
  checklistLinkStyle,
  itemContentStyle,
} from "sections/Profile/constants";

const GET_IS_PAYMENT_SETUP_CHECK = gql`
  query SetupPaymentsChecklistItemIsPaymentSetup($userId: ID!) {
    isPaymentSetup(userId: $userId)
  }
`;

type Props = {
  setError: (msg: string) => void;
  userId: string;
};

export const SetupPaymentsChecklistItem = ({ setError, userId }: Props) => {
  const [paymentIsSetup, setPaymentIsSetup] = useState(false);

  const { data, loading } =
    useQuery<SetupPaymentsChecklistItemIsPaymentSetupQuery>(
      GET_IS_PAYMENT_SETUP_CHECK,
      {
        variables: { userId },
        fetchPolicy: "network-only",
        onError: (error) => {
          setError(error.message);
        },
      }
    );

  useEffect(() => {
    if (data) return setPaymentIsSetup(data.isPaymentSetup);
  }, [data]);

  return (
    <ChecklistItem
      loading={loading}
      trackedForCompletion
      complete={paymentIsSetup}
      title={
        <div className="flex-center gap-x-1 group">
          Update your payment information
          <Link
            className={checklistLinkStyle}
            href="https://www.notion.so/tutoredbyteachers/Tipalti-Set-Up-60f668e610144f1fb7c3d1401689a12d"
            target="__blank"
          >
            Tipalti Setup
            <Icon
              size={4}
              icon="externalLink"
              color="text-blue-800 group-hover:text-blue-600"
            />
          </Link>
        </div>
      }
      collapsibleContent={
        paymentIsSetup ? null : (
          <div className={itemContentStyle}>
            You must connect your bank account to your new Tipalti account.
          </div>
        )
      }
    />
  );
};
