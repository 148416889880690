import { CohortSessionStudentAttendanceStatus } from "@generated/graphql";

export const MIN_WEEKDAY_WIDTH = 110;
export const TABLE_OFFSET_HEIGHT = 482;

export const AttendanceStatusColors = {
  [CohortSessionStudentAttendanceStatus.Unknown]: "bg-gray-500",
  [CohortSessionStudentAttendanceStatus.Present]: "bg-emerald-500",
  [CohortSessionStudentAttendanceStatus.Absent]: "bg-rose-500",
  [CohortSessionStudentAttendanceStatus.Partial]: "bg-amber-500",
  notHeld: "bg-purple-500",
};

export const NOT_IN_ENGAGEMENT_STYLE = "!bg-gray-200/70";
export const FUTURE_STYLE = "bg-sky-900/[0.03]";
export const ENGAGEMENT_EXCLUSION_STYLE = "!bg-blue-900/[0.10]";
export const OUTSIDE_DATE_FILTER_STYLE = "bg-fuchsia-900/10";
export const OUTSIDE_STUDENT_RANGE_STYLE = "!bg-gray-200/60";
