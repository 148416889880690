import clsx from "clsx";
import { PropsWithChildren, ReactNode, useId } from "react";
import { ITooltip, Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Icon } from "./Icon";

export type ReactTooltipProps = Exclude<
  ITooltip,
  "children" | "content" | "html" | "anchorId"
>;

type Props = {
  className?: string;
  content: ReactNode;
  disabled?: boolean;
  contentClassName?: string;
  tooltipProps?: ReactTooltipProps;
};

export const Tooltip = ({
  content,
  children,
  disabled,
  className,
  tooltipProps,
  contentClassName,
}: PropsWithChildren<Props>) => {
  const id = useId();

  return (
    <>
      <span
        id={id}
        className={clsx(
          "h-fit w-fit",
          !disabled && "cursor-pointer",
          Boolean(children) && className
        )}
      >
        {children || (
          <Icon
            icon="info"
            color="text-blue-600"
            size={5}
            className={className}
          />
        )}
      </span>
      {!disabled && (
        <ReactTooltip
          anchorId={id}
          {...tooltipProps}
          className="z-[4000] cursor-pointer"
        >
          <div>
            {typeof content === "string" ? (
              <div className={contentClassName ?? "text-sm text-center"}>
                {content}
              </div>
            ) : (
              <>{content}</>
            )}
          </div>
        </ReactTooltip>
      )}
    </>
  );
};
