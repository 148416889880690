import { Holiday } from "@generated/graphql";
import dayjs from "dayjs";

export type CurrentCellRender = number | dayjs.Dayjs | string;
export type BuildHolidayMapHoliday = {
  id: Holiday["id"];
  type: Holiday["type"];
  startDate: string;
  endDate: string;
};

export type CohortEventInstanceStartDateTime = Date;

export enum OnUpdateViewpoint {
  Past,
  Present,
  Future,
  Static,
}
