import { ConfigProvider, DatePicker, theme } from "antd";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { defaultCellRenderer } from "../cellRenderers";
import { CurrentCellRender } from "../types";

type Props = {
  id: string;
  viewDate: Date;
  displayMonth: Date;
  setViewDate: (date: Date) => void;
  disabledDayFn?: (date: dayjs.Dayjs) => boolean;
  cellRenderer?: (current: dayjs.Dayjs, month: dayjs.Dayjs) => ReactNode;
};

export const NavigationCalendar = ({
  id,
  viewDate,
  displayMonth,
  setViewDate,
  cellRenderer,
  disabledDayFn,
}: Props) => {
  const defaultDay = dayjs(displayMonth);

  const cellRendererFn = (current: number | dayjs.Dayjs | string) =>
    cellRenderer
      ? cellRenderer(dayjs(current), defaultDay)
      : defaultCellRenderer(dayjs(current), defaultDay, viewDate);

  return (
    <ConfigProvider theme={{ algorithm: theme.compactAlgorithm }}>
      <DatePicker
        open
        autoFocus
        mode="date"
        prevIcon={null}
        nextIcon={null}
        showNow={false}
        value={defaultDay}
        superNextIcon={null}
        superPrevIcon={null}
        defaultValue={defaultDay}
        popupClassName="navigation-cal"
        onChange={(value) => setViewDate(value.toDate())}
        disabledDate={(date) => disabledDayFn?.(date) || false}
        style={{ visibility: "hidden", top: "-22px", height: 0 }}
        cellRender={(current: CurrentCellRender) => cellRendererFn(current)}
        getPopupContainer={() => document.getElementById(id) as HTMLElement}
      />
    </ConfigProvider>
  );
};
