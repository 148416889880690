import { gql, useMutation } from "@apollo/client";
import {
  CohortSessionStudentAttendanceStatus,
  EditSingleStudentAttendanceMutation,
  EditSingleStudentAttendanceMutationVariables,
  SessionStudentEvaluationTable_StudentAttendanceRecordFragment,
} from "@generated/graphql";
import {
  Icon,
  SelectMenuOption,
  Tooltip,
  triggerErrorToast,
} from "components/shared";
import {
  AttendanceStatusButtonsPanel,
  DisplayNameSaveOnChangeInput,
} from "components/shared/AttendanceGrades";
import { NO_MATCH } from "components/shared/AttendanceGrades/constants";
import { ATTENDANCE_MIN_WIDTH } from "components/shared/AttendanceGrades/SessionStudentEvaluationTable/constants";
import {
  isNotAttended,
  makeAttendanceKey,
} from "components/shared/AttendanceGrades/utils";
import { useTutorDashboardData } from "contexts/TutorDashboardDataProvider";
import { ReactNode, useEffect, useState } from "react";
import { OnSaveStudentAttendanceProps } from "sections/UserDashboard/TutorDashboard/components/TutoringTab/types";

import {
  noRecordBadge,
  noShowDayTooltip,
  notHappenedYetBadge,
} from "../../helpers";
import { AttendanceNote } from "./components/AttendanceNote";

const EDIT_SINGLE_STUDENT_ATTENDANCE = gql`
  mutation EditSingleStudentAttendance(
    $input: [EditCohortSessionStudentAttendanceInput!]!
  ) {
    editCohortSessionStudentAttendance(input: $input) {
      id
      notes
      status
      displayName
    }
  }
`;

type Props = {
  studentId: string;
  startDateTime: Date;
  cohortSessionId: string;
  isCohortNoShowDay: boolean;
  isEngagementNoShowDay: boolean;
  studentAttendanceEntry: SessionStudentEvaluationTable_StudentAttendanceRecordFragment | null;
};

export const StudentAttendanceSection = ({
  studentId,
  startDateTime,
  cohortSessionId,
  isCohortNoShowDay,
  isEngagementNoShowDay,
  studentAttendanceEntry,
}: Props) => {
  const {
    backupStudentNames,
    setBackupStudentNames,
    studentMatchData,
    activeNote,
    setActiveNote,
  } = useTutorDashboardData();

  const [editSingleStudentAttendanceMutation, { loading }] = useMutation<
    EditSingleStudentAttendanceMutation,
    EditSingleStudentAttendanceMutationVariables
  >(EDIT_SINGLE_STUDENT_ATTENDANCE, {
    update: (cache, { data }) => {
      if (data) {
        const updatedAttendance = data.editCohortSessionStudentAttendance[0];
        cache.modify({
          id: `TutorDashboardCohortSessionStudentAttendance:${updatedAttendance.id}`,
          fields: {
            status: () => updatedAttendance.status,
            notes: () => updatedAttendance.notes ?? null,
            displayName: () => updatedAttendance.displayName ?? null,
          },
        });
      }
    },
    onError: (error) =>
      triggerErrorToast({
        message: "Looks like something went wrong.",
        sub: "We weren't able to update this student's attendance successfully.",
        log: error,
      }),
  });

  const currentNote = studentAttendanceEntry?.notes || null;
  const attendanceKey = makeAttendanceKey(cohortSessionId, studentId);
  const hasNote = activeNote === attendanceKey || !!currentNote;
  const [showNote, setShowNote] = useState<boolean>(hasNote);
  const studentDisplayNameMatchData = studentMatchData[attendanceKey];
  const disabled = studentAttendanceEntry?.status
    ? isNotAttended(studentAttendanceEntry.status)
    : true;

  useEffect(() => {
    if (currentNote !== null && showNote === false) setShowNote(true);
    if (currentNote === null && showNote === true) setShowNote(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNote]);

  const updateStudentAttendance = async ({
    status,
    notes,
    displayName,
  }: OnSaveStudentAttendanceProps) => {
    if (!studentAttendanceEntry) return;
    let nameUpdate = displayName || undefined;
    const statusToAway = status && !disabled && isNotAttended(status);
    const statusToPresent = status && disabled && !isNotAttended(status);
    if (statusToPresent) {
      nameUpdate =
        studentAttendanceEntry.displayName ||
        backupStudentNames[attendanceKey] ||
        studentDisplayNameMatchData?.match;
    }
    if (statusToAway) nameUpdate = NO_MATCH;
    if (notes === undefined) setActiveNote("");

    await editSingleStudentAttendanceMutation({
      variables: {
        input: [
          {
            id: studentAttendanceEntry.id,
            notes,
            status,
            studentId,
            cohortSessionId,
            displayName: nameUpdate,
          },
        ],
      },
    });
  };

  const badgeWrapper = (badge: ReactNode) => (
    <div className="flex flex-1 flex-center overflow-hidden">
      <div
        className="flex pl-[32px]"
        style={{ width: `${ATTENDANCE_MIN_WIDTH}px` }}
      >
        {badge}
      </div>
    </div>
  );

  if (studentAttendanceEntry === null)
    return new Date(startDateTime).getTime() > Date.now()
      ? badgeWrapper(notHappenedYetBadge())
      : badgeWrapper(noRecordBadge("attendance"));

  if (isEngagementNoShowDay || isCohortNoShowDay)
    return badgeWrapper(noShowDayTooltip(isEngagementNoShowDay));

  return (
    <div
      className="flex flex-1 flex-col flex-center py-2 w-auto"
      style={{ minWidth: `${ATTENDANCE_MIN_WIDTH}px` }}
    >
      <div className="flex flex-col">
        <div className="flex w-fit h-full items-center justify-start gap-x-2">
          <AttendanceStatusButtonsPanel
            status={studentAttendanceEntry.status}
            updateStudentAttendance={(status) =>
              updateStudentAttendance({
                status: status as CohortSessionStudentAttendanceStatus,
              })
            }
          />

          <DisplayNameSaveOnChangeInput
            disabled={disabled}
            backupStudentName={backupStudentNames[attendanceKey]}
            matchData={studentDisplayNameMatchData || undefined}
            savedDisplayName={studentAttendanceEntry.displayName}
            onSave={(option: SelectMenuOption) => {
              const displayName = String(option.value);
              setBackupStudentNames({
                ...backupStudentNames,
                [attendanceKey]:
                  displayName === studentDisplayNameMatchData?.match
                    ? undefined
                    : displayName,
              });
              updateStudentAttendance({ displayName: displayName });
            }}
          />

          <Tooltip tooltipProps={{ place: "bottom" }} content="Leave a note">
            <span
              className="flex cursor-pointer group items-center justify-start text-sm"
              onClick={() => {
                setActiveNote(showNote ? "" : attendanceKey);
                setShowNote(!showNote);
              }}
            >
              <Icon
                size={8}
                icon="note"
                color="text-slate-700 group-hover:text-blue-500"
              />
            </span>
          </Tooltip>
        </div>

        <AttendanceNote
          loading={loading}
          note={currentNote}
          showNote={showNote}
          attendanceId={attendanceKey}
          onUpdateNote={(notes) => updateStudentAttendance({ notes })}
        />
      </div>
    </div>
  );
};
