import { UserReferralSourceCard_UserFragment } from "@generated/graphql";
import { Modal } from "components/shared";
import { useRef } from "react";
import { UserReferralSourceModalBody } from "./UserReferralSourceModalBody";

type Props = {
  show: boolean;
  user: UserReferralSourceCard_UserFragment;
  closeModal: () => void;
};

export const UserReferralSourceModal = ({ show, user, closeModal }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      show={show}
      width="medium"
      initialFocus={containerRef}
      icon={<Modal.Icon icon="referral" />}
      title="How did you hear about Tutored by Teachers?"
      subtitle="Please let us know how you heard about us!"
      onClose={closeModal}
    >
      <div className="flex flex-col pt-4 px-3 h-fit gap-y-4" ref={containerRef}>
        <UserReferralSourceModalBody user={user} closeModal={closeModal} />
      </div>
    </Modal>
  );
};
