import { Badge } from "./Badge";
import clsx from "clsx";

type Props = {
  className?: string;
};

export const StaffedBadge = ({ className }: Props) => {
  return (
    <Badge className={clsx("text-emerald-900 bg-emerald-200", className)}>
      Staffed
    </Badge>
  );
};
