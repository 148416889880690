import { gql } from "@apollo/client";
import {
  UserReferralSource,
  UserReferralSourceCard_UserFragment,
} from "@generated/graphql";
import { getUserReferralSourceText } from "@utils/labels";
import { Card } from "components/shared";
import {
  CardDetailsRow,
  getEditModalOpenHeader,
  HeaderColors,
} from "components/shared/Card";
import { useState } from "react";
import { getNoDataCallToAction } from "sections/Profile/helpers";
import { UserReferralSourceModal } from "./components/UserReferralSourceModal";

UserReferralSourceCard.fragments = {
  user: gql`
    fragment UserReferralSourceCard_User on User {
      id
      referralName
      referralSources
      otherReferralSource
    }
  `,
};

type Props = {
  className?: string;
  user: UserReferralSourceCard_UserFragment;
};

export function UserReferralSourceCard({ user, className }: Props) {
  const [showModal, setShowModal] = useState(false);

  const { Other, CurrentTeacher } = UserReferralSource;
  const { referralSources, referralName, otherReferralSource } = user;

  const hasNoSources = referralSources.length === 0;
  const nonInputSources = referralSources.filter(
    (s) => s !== Other && s !== CurrentTeacher
  );

  const rows = [
    ["Current TbT Teacher Referral", referralName],
    [
      "Referral Sources",
      nonInputSources.map(getUserReferralSourceText).join(", "),
    ],
    ["Other Referral Source", otherReferralSource],
  ].filter(
    (_, i) =>
      (referralName || i !== 0) &&
      (nonInputSources.length > 0 || i !== 1) &&
      (otherReferralSource || i !== 2)
  ) as CardDetailsRow[];

  return (
    <>
      <Card
        icon="referral"
        className={className}
        headerOverlayColor={HeaderColors.Pink}
        rows={hasNoSources ? undefined : rows}
        header={getEditModalOpenHeader(
          "How did you hear about Tutored by Teachers?",
          () => setShowModal(true)
        )}
      >
        {hasNoSources && getNoDataCallToAction(() => setShowModal(true))}
      </Card>

      <UserReferralSourceModal
        user={user}
        show={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
