import { assertUnreachable } from "@utils/types";
import { useGroupFilterInputs } from "contexts/FilterInputsProvider";
import { Filters } from "../Filters";
import { FilterType } from "../types";

type Props = { tableName: string; filterType: FilterType };

export const LiveTableFilters = ({ tableName, filterType }: Props) => {
  const { getFilterBoolean, setFilterBoolean } =
    useGroupFilterInputs(tableName);

  return (
    <Filters
      filters={getFilters(getFilterBoolean, setFilterBoolean, filterType)}
    />
  );
};

const getFilters = (
  getFilterBoolean: (key: string) => boolean | null,
  setFilterBoolean: (key: string, value: boolean) => void,
  filterType: FilterType
) => [
  {
    name: "All",
    tooltip: `Show All ${filterType}`,
    selected: !getFilterBoolean("liveEntitiesOnly"),
    onClick: () => setFilterBoolean("liveEntitiesOnly", false),
  },
  {
    name: "Live",
    tooltip: getTooltip(filterType),
    selected: getFilterBoolean("liveEntitiesOnly") ?? false,
    onClick: (newState: boolean) =>
      setFilterBoolean("liveEntitiesOnly", newState),
  },
];

const getTooltip = (filterType: FilterType) => {
  switch (filterType) {
    case FilterType.Organizations:
      return "Only show Organizations with live Cohorts";
    case FilterType.Engagements:
      return "Only show Engagements with live Cohorts";
    case FilterType.Cohorts:
      return "Only show live Cohorts";
    default:
      assertUnreachable(filterType, "FilterType");
  }
};
