import { RosterRole } from "@generated/graphql";
import { RosterRoleTextMode, getRosterRoleText } from "@utils/labels";
import clsx from "clsx";
import { Badge } from "./Badge";

const rosterRoleBadgeMap: {
  [key in RosterRole]: string;
} = {
  [RosterRole.TutorTeacher]: "text-indigo-900 bg-indigo-200",
  [RosterRole.MentorTeacher]: "text-emerald-900 bg-emerald-200",
  [RosterRole.SubstituteTeacher]: "text-sky-900 bg-sky-200",
  [RosterRole.EngagementManager]: "text-orange-900 bg-orange-200",
};

type Props = {
  rosterRole: RosterRole;
  mode?: RosterRoleTextMode;
  className?: string;
};

export const RosterRoleBadge = ({
  rosterRole,
  mode = "short",
  className,
}: Props) => (
  <Badge className={clsx(rosterRoleBadgeMap[rosterRole], className)}>
    {getRosterRoleText(rosterRole, mode)}
  </Badge>
);
