import { UserEthnicity } from "@generated/graphql";
import { userEthnicityOptions } from "@utils/selectMenuOptions";
import { useMemo } from "react";
import { SelectMenu, SelectMenuProps } from "../SelectMenu";

type Props = {
  className?: string;
  initialEthnicity?: UserEthnicity | null;
  selectMenuProps?: Exclude<SelectMenuProps, "initialIndex">;
  onSelect: (ethnicity: UserEthnicity | null) => void;
};

export const EthnicitySelectMenu = ({
  className,
  selectMenuProps,
  initialEthnicity,
  onSelect,
}: Props) => {
  const initialIndex = useMemo(() => {
    return userEthnicityOptions.findIndex(
      ({ ethnicity }) => ethnicity === initialEthnicity
    );
  }, [initialEthnicity]);

  return (
    <SelectMenu
      labelText="Ethnicity"
      {...selectMenuProps}
      className={className}
      initialIndex={initialIndex}
      options={userEthnicityOptions}
      onSelect={({ ethnicity }) => onSelect(ethnicity)}
    />
  );
};
