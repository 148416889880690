import { gql } from "@apollo/client";
import {
  CohortAssignmentSubject,
  CohortAssignmentSubSubject,
  CohortSessionStudentAttendanceStatus,
  StudentAttendanceGradingItem_CohortSessionStudentAttendanceFragment,
  StudentStatus,
} from "@generated/graphql";
import clsx from "clsx";
import {
  CohortAssignmentSubjectBadge,
  Icon,
  IconText,
  SelectMenu,
  SelectMenuOption,
  Tooltip,
} from "components/shared";
import { getStudentAttendanceIcon } from "components/shared/AttendanceGrades";
import { useEffect, useMemo, useState } from "react";
import { CiStickyNote } from "react-icons/ci";
import { AttendanceStatusPillButton } from "./AttendanceStatusPillButton";
import { GradingPanel } from "./GradingPanel";
import { RoboDisplayNameLabel, SavedDisplayNameLabel } from "./SpecialLabels";
import { getStudentStatusTooltip } from "./helpers";
import {
  DraftNotes,
  StudentGrades,
  StudentLiveParticipantMatch,
} from "./types";
import { getAttendanceStatusStyles } from "./utils";

const statusButtonOptions: CohortSessionStudentAttendanceStatus[] = [
  CohortSessionStudentAttendanceStatus.Present,
  CohortSessionStudentAttendanceStatus.Partial,
  CohortSessionStudentAttendanceStatus.Absent,
];

const NO_MATCH_OPTION: SelectMenuOption = {
  id: "NONE",
  value: "No match",
};

StudentAttendanceGradingItem.fragments = {
  cohortSessionStudentAttendance: gql`
    fragment StudentAttendanceGradingItem_CohortSessionStudentAttendance on CohortSessionStudentAttendance {
      id
      status
      notes
      student {
        id
        fullName
        externalId
      }
      cohort {
        id
        startDate
        students {
          id
          startDate
          studentId
          studentStatus
        }
        engagement {
          id
          startDate
        }
      }
    }
  `,
  cohortSessionStudentGrading: gql`
    fragment StudentAttendanceGradingItem_CohortSessionStudentGrading on CohortSessionStudentGrading {
      id
      ...GradingPanel_CohortSessionStudentGrading
    }
    ${GradingPanel.fragments.cohortSessionStudentGrading}
  `,
  tutorDashboardCohortSessionStudentGrading: gql`
    fragment StudentAttendanceGradingItem_StudentGradingRecord on TutorDashboardCohortSessionStudentGrading {
      id
      ...GradingPanel_StudentGradingRecord
    }
    ${GradingPanel.fragments.tutorDashboardCohortSessionStudentGrading}
  `,
};

type Props<G extends StudentGrades> = {
  updateStudentAttendance: (
    attendanceStatus: CohortSessionStudentAttendanceStatus
  ) => void;
  updateStudentGrade: (grades: G) => void;
  updateStudentDisplayName: (displayName: string | null) => void;
  setDraftNotes: (notes: DraftNotes) => void;
  draftNotes: DraftNotes | null;
  studentAttendance: StudentAttendanceGradingItem_CohortSessionStudentAttendanceFragment;
  matchData: StudentLiveParticipantMatch | undefined;
  studentGrades?: G;
  subject?: CohortAssignmentSubject;
  subSubject?: CohortAssignmentSubSubject;
  displayName: string | null;
};

export function StudentAttendanceGradingItem<G extends StudentGrades>({
  updateStudentGrade,
  updateStudentAttendance,
  updateStudentDisplayName,
  setDraftNotes,
  draftNotes,
  studentAttendance: {
    id: studentAttendanceId,
    status,
    notes,
    student: { id, fullName, externalId },
    cohort: {
      students,
      startDate: cohortStartDate,
      engagement: { startDate: engStartDate },
    },
  },
  subject,
  subSubject,
  matchData,
  displayName,
  studentGrades,
}: Props<G>) {
  const [showNote, setShowNote] = useState<boolean>(!!notes);
  const showKey = showNote || (subject && studentGrades);
  const showSubject = showKey && subject && studentGrades;
  const [studentMatchedOption, setStudentMatchedOption] =
    useState<SelectMenuOption>(NO_MATCH_OPTION);

  const { studentStatus, studentStart } = useMemo(() => {
    const student = students.find(({ studentId: sId }) => sId === id);

    return {
      studentStatus: student?.studentStatus ?? StudentStatus.Fixture,
      studentStart: student?.startDate ?? cohortStartDate ?? engStartDate,
    };
  }, [students, id, cohortStartDate, engStartDate]);

  const mapMatchDataToSelectMenuOptions = (
    displayName: string | null,
    matchData?: StudentLiveParticipantMatch
  ) => {
    const options: SelectMenuOption[] = (matchData?.distances ?? [])
      .filter((distance) => !!distance.participant)
      .map((distance, index) => {
        const { selectedLabel, subValue } =
          matchData?.match === distance.participant
            ? {
                selectedLabel: (
                  <RoboDisplayNameLabel displayName={distance.participant} />
                ),
                subValue: "(Robo suggestion)",
              }
            : { selectedLabel: undefined, subValue: undefined };
        return {
          id: `${distance.participant}_${index}`,
          value: distance.participant,
          selectedLabel,
          subValue,
        };
      });

    if (!displayName) {
      return [NO_MATCH_OPTION, ...options];
    }

    // Find the option that matches the provided displayName prop (from the DB).
    const providedOption = options.find(({ value }) => value === displayName);

    if (providedOption) {
      setStudentMatchedOption(providedOption);
      return [NO_MATCH_OPTION, ...options];
    } else {
      // If the displayName is not in the list of options, add it to the top of the list.
      const forcedOption: SelectMenuOption = {
        id: "forcedOption",
        value: displayName,
        selectedLabel: <SavedDisplayNameLabel displayName={displayName} />,
        subValue: "(Saved display name)",
      };
      setStudentMatchedOption(forcedOption);
      return [NO_MATCH_OPTION, forcedOption, ...options];
    }
  };

  const isNameSuggestionsDisabled = (
    status: CohortSessionStudentAttendanceStatus
  ) => {
    switch (status) {
      case CohortSessionStudentAttendanceStatus.Present:
      case CohortSessionStudentAttendanceStatus.Partial:
        return false;
      default:
        return true;
    }
  };

  // Update the student's display name when the matched option changes.
  useEffect(() => {
    updateStudentDisplayName(
      studentMatchedOption.id === NO_MATCH_OPTION.id
        ? null
        : String(studentMatchedOption?.value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentMatchedOption]);

  // When "No Match" option is current selection, auto-select the "matched" option.
  // Otherwise, if the "matched" option is not in the list of options, select
  // the "forced" option, which is the student's previously saved display name.
  useEffect(() => {
    if (
      !isNameSuggestionsDisabled(status) &&
      studentMatchedOption.id === NO_MATCH_OPTION.id
    ) {
      const matchedOption = studentMatchOptions.find(
        ({ value }) => value === matchData?.match
      );
      if (matchedOption) {
        setStudentMatchedOption(matchedOption);
      } else {
        const forcedOption = studentMatchOptions.find(
          ({ value }) => value === displayName
        );
        if (forcedOption) {
          setStudentMatchedOption(forcedOption);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (studentMatchedOption.id === NO_MATCH_OPTION.id && matchData?.match) {
      const matchedOption = studentMatchOptions.find(
        ({ value }) => value === matchData?.match
      );
      if (matchedOption) {
        setStudentMatchedOption(matchedOption);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchData]);

  const studentMatchOptions = useMemo(
    () => mapMatchDataToSelectMenuOptions(displayName, matchData),
    [displayName, matchData]
  );

  const initialIndex = useMemo(
    () =>
      studentMatchOptions.findIndex(
        ({ id }) => id === studentMatchedOption?.id
      ),
    [studentMatchOptions, studentMatchedOption]
  );

  const renderStatusButtons = statusButtonOptions.map((buttonStatus) => (
    <AttendanceStatusPillButton
      key={buttonStatus}
      status={buttonStatus}
      isSelected={status === buttonStatus}
      onChange={() => {
        if (status === buttonStatus) {
          updateStudentAttendance(CohortSessionStudentAttendanceStatus.Unknown);
          setStudentMatchedOption(NO_MATCH_OPTION);
          return;
        }
        if (isNameSuggestionsDisabled(buttonStatus)) {
          setStudentMatchedOption(NO_MATCH_OPTION);
        }
        updateStudentAttendance(buttonStatus);
      }}
    />
  ));

  const renderUserNameSuggestions = (
    <div className="flex items-center">
      <SelectMenu
        disabled={isNameSuggestionsDisabled(status)}
        listAlignment="right"
        className="w-[18ch]"
        options={studentMatchOptions}
        initialIndex={initialIndex < 0 ? undefined : initialIndex}
        onSelect={(item) =>
          setStudentMatchedOption(
            !item || item.id === NO_MATCH_OPTION.id ? NO_MATCH_OPTION : item
          )
        }
      />
      <Tooltip
        className=" ml-2 cursor-help"
        content={
          <div className="block w-36 text-sm text-center">
            Select student screen name during the video session
          </div>
        }
        tooltipProps={{
          place: "bottom",
        }}
      />
    </div>
  );

  const renderAttendanceNote = (
    <div className="relative w-full mt-2">
      <label htmlFor="note" className="sr-only">
        Add a note
      </label>
      <textarea
        rows={2}
        name="note"
        id="note"
        onFocus={() =>
          setDraftNotes({ notes: notes ?? "", id: studentAttendanceId })
        }
        className="flex w-full justify-center border-gray-300 border focus:border-2 items-start pb-1 focus:border-blue-500 focus:ring-0 sm:text-sm rounded-lg h-10"
        placeholder="Add your note..."
        value={
          studentAttendanceId === draftNotes?.id
            ? draftNotes.notes
            : notes ?? ""
        }
        onChange={(event) =>
          setDraftNotes({ id: studentAttendanceId, notes: event.target.value })
        }
      />
    </div>
  );

  return (
    <div
      key={studentAttendanceId}
      className="flex justify-between items-start rounded-[10px] border border-slate-300 p-4 shadow-md w-auto min-w-fit bg-white"
    >
      <Tooltip
        disabled={studentStatus === StudentStatus.Fixture}
        tooltipProps={{ place: "right" }}
        className="flex items-center gap-1 cursor-pointer"
        content={getStudentStatusTooltip(studentStatus, new Date(studentStart))}
      >
        <div className="flex flex-col justify-start gap-1 items-start">
          <div className="flex items-center gap-1">
            <div
              className={clsx(
                "flex p-1 rounded-full pl-1 pr-4 border border-gray-200 shadow-sm",
                getAttendanceStatusStyles(status).pillColor
              )}
            >
              <div className="flex justify-start items-center gap-1 text-ellipsis text-lg font-medium text-white whitespace-nowrap">
                {getStudentAttendanceIcon(status, "text-gray-100", "", true)}

                {fullName || `Student ${externalId}`}
              </div>
            </div>
            {studentStatus !== StudentStatus.Fixture && (
              <Icon
                icon="new"
                size={5}
                color={
                  studentStatus === StudentStatus.New
                    ? "text-yellow-500"
                    : "text-indigo-500"
                }
              />
            )}
          </div>
          <div className="flex flex-col gap-y-2 pl-2">
            {showKey && <IconText icon="key" child={externalId} />}
            {showSubject && (
              <div className="w-fit">
                <CohortAssignmentSubjectBadge
                  cohortSubject={subject}
                  cohortSubSubject={subSubject}
                />
              </div>
            )}
          </div>
        </div>
      </Tooltip>

      <div className="flex flex-col flex-center h-full w-fit">
        <div className="flex w-full h-full items-center justify-end gap-x-3">
          {renderStatusButtons}
          {renderUserNameSuggestions}
          <Tooltip content="Leave a note">
            <span
              onClick={() => setShowNote(!showNote)}
              className="flex cursor-pointer items-center justify-start text-sm text-gray-600 hover:text-blue-400"
            >
              <CiStickyNote className="w-8 h-8" />
            </span>
          </Tooltip>
        </div>
        {studentGrades && subject && (
          <GradingPanel
            key={studentAttendanceId}
            subject={subject}
            studentGrades={studentGrades}
            className="w-full mt-2 flex-1 pl-2"
            disableGrading={
              status !== CohortSessionStudentAttendanceStatus.Present &&
              status !== CohortSessionStudentAttendanceStatus.Partial
            }
            updateStudentGrade={updateStudentGrade}
          />
        )}
        {showNote && renderAttendanceNote}
      </div>
    </div>
  );
}
