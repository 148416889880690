import clsx from "clsx";
import { PillButton } from "../Buttons";

export enum YesNoNullTheme {
  Default,
  MiniMuted,
}

export type YesNoNull = boolean | null;

type Props = {
  required?: boolean;
  className?: string;
  readOnly?: boolean;
  isYes: boolean | null;
  theme?: YesNoNullTheme;
  onChange?: (isYes: boolean | null) => void;
};

export const YesOrNoInput = ({
  isYes,
  className,
  readOnly = false,
  required = false,
  theme = YesNoNullTheme.Default,
  onChange,
}: Props) => (
  <div className={clsx("flex items-center gap-x-2", className)}>
    {/* [YES Button] | Show unless state is read-Only and value is NOT TRUE */}
    {(!readOnly || isYes) && (
      <PillButton
        onChange={() => !readOnly && onChange?.(isYes ? null : true)}
        name={
          <div className={clsx(!isYes && pillInternalStyle(theme))}>Yes</div>
        }
        className={clsx(
          readOnly && "!cursor-default",
          isYes ? [selectedStyle, getOptionColor(isYes, theme)] : defaultStyle,
          pillStyle(theme)
        )}
      />
    )}

    {/* [NO Button] | Show unless state is read-Only and value is NOT FALSE */}
    {(!readOnly || isYes === false) && (
      <PillButton
        onChange={() => !readOnly && onChange?.(isYes === false ? null : false)}
        className={clsx(
          readOnly && "!cursor-default",
          isYes === false
            ? [selectedStyle, getOptionColor(isYes, theme)]
            : defaultStyle,
          pillStyle(theme)
        )}
        name={
          <div className={clsx(isYes !== false && pillInternalStyle(theme))}>
            No
          </div>
        }
      />
    )}

    {/* Required Indicator */}
    {required && !readOnly && (
      <span className="text-gray-800 text-sm -ml-[3px] mb-[4px] font-semibold">
        *
      </span>
    )}
  </div>
);

const getOptionColor = (isYes: boolean, theme: YesNoNullTheme) => {
  if (isYes) {
    switch (theme) {
      case YesNoNullTheme.Default:
        return "border-emerald-600 bg-emerald-500 bg-opacity-90 text-white";
      case YesNoNullTheme.MiniMuted:
        return "border-emerald-300 bg-emerald-200 !text-emerald-800";
    }
  }

  if (isYes === false) {
    switch (theme) {
      case YesNoNullTheme.Default:
        return "!border-rose-600 !bg-rose-500 !bg-opacity-80 !text-white";
      case YesNoNullTheme.MiniMuted:
        return "!border-rose-300 !bg-rose-200 !text-rose-800";
    }
  }
};

export const pillStyle = (theme: YesNoNullTheme) => {
  switch (theme) {
    case YesNoNullTheme.Default:
      return "h-7 px-3 border-2";
    case YesNoNullTheme.MiniMuted:
      return "!h-5 !px-2 border";
  }
};

export const pillInternalStyle = (theme: YesNoNullTheme) => {
  switch (theme) {
    case YesNoNullTheme.Default:
      return "border border-white";
    case YesNoNullTheme.MiniMuted:
      return "";
  }
};

const selectedStyle = "text-white";
const defaultStyle = "border-gray-300 bg-white text-gray-700";
