import { AccountRole } from "@generated/graphql";
import { useAuth } from "contexts/AuthProvider";
import { useGroupFilterInputs } from "contexts/FilterInputsProvider";
import { Filters } from "../Filters";

type Props = { tableName: string };

export const UserTableFilters = ({ tableName }: Props) => {
  const { isManagingMode } = useAuth();
  const { getFilterBoolean, setFilterBoolean } =
    useGroupFilterInputs(tableName);

  return (
    <Filters
      filters={getFilters(getFilterBoolean, setFilterBoolean, isManagingMode)}
    />
  );
};

const getFilters = (
  getFilterBoolean: (key: string) => boolean | null,
  setFilterBoolean: (key: string, value: boolean) => void,
  isManagingMode: boolean
) => {
  const isAllFiltersSelected =
    (getFilterBoolean(AccountRole.Admin) ?? false) &&
    (getFilterBoolean(AccountRole.TutorTeacher) ?? false) &&
    (getFilterBoolean(AccountRole.MentorTeacher) ?? false);

  const filtersArray = [AccountRole.TutorTeacher, AccountRole.MentorTeacher];
  if (isManagingMode) filtersArray.push(AccountRole.Admin);

  const onFilterClick = (role: AccountRole, value: boolean) => {
    if (isAllFiltersSelected) {
      filtersArray
        .filter((f) => f !== role)
        .forEach((f) => setFilterBoolean(f, false));
    } else {
      setFilterBoolean(role, value);
    }
  };

  return [
    {
      name: "All",
      selected: isAllFiltersSelected,
      tooltip: `Show All Users`,
      onClick: () => {
        setFilterBoolean(AccountRole.Admin, true);
        setFilterBoolean(AccountRole.MentorTeacher, true);
        setFilterBoolean(AccountRole.TutorTeacher, true);
      },
    },
    {
      name: "Admins",
      selected:
        !isAllFiltersSelected && (getFilterBoolean(AccountRole.Admin) || false),
      show: isManagingMode,
      tooltip: "Show Admin Users",
      onClick: (newState: boolean) =>
        onFilterClick(AccountRole.Admin, newState),
    },
    {
      name: "Mentors",
      selected:
        !isAllFiltersSelected &&
        (getFilterBoolean(AccountRole.MentorTeacher) || false),
      tooltip: "Show Mentor Teachers",
      onClick: (newState: boolean) =>
        onFilterClick(AccountRole.MentorTeacher, newState),
    },
    {
      name: "Teachers",
      selected:
        !isAllFiltersSelected &&
        (getFilterBoolean(AccountRole.TutorTeacher) || false),
      tooltip: "Show Teacher-Tutors",
      onClick: (newState: boolean) =>
        onFilterClick(AccountRole.TutorTeacher, newState),
    },
  ];
};
