import clsx from "clsx";
import { Button, Icon, Tooltip } from "components/shared";
import {
  ellEldTooltipText,
  itemSectionHeaderStyle,
  itemSubHeaderStyle,
  testPrepTooltipText,
} from "./constants";

export const renderContent = (text: string) => (
  <div className="text-sm text-center font-normal max-w-[32ch]">{text}</div>
);

export const EllEldTooltip = ({ inCard = false }: { inCard?: boolean }) => (
  <Tooltip
    className={clsx(
      !inCard && itemSectionHeaderStyle,
      "flex items-center gap-x-1"
    )}
    content={renderContent(ellEldTooltipText)}
  >
    ELL/ELD
    <Icon icon="infoCircle" size={3} color="text-blue-500" className="mb-2" />
  </Tooltip>
);

export const TestPrepTooltip = ({ inCard = false }: { inCard?: boolean }) => (
  <Tooltip
    className={clsx(
      !inCard && itemSectionHeaderStyle,
      "flex items-center gap-x-1"
    )}
    content={renderContent(testPrepTooltipText)}
  >
    PSAT/SAT/ACT
    <Icon icon="infoCircle" size={3} color="text-blue-500" className="mb-2" />
  </Tooltip>
);

export const getNoDataCallToAction = (
  openModal: () => void,
  text = "Please let us know about how you heard about us."
) => (
  <div className="flex w-full items-center justify-between gap-x-3 p-3">
    <div className={clsx(itemSubHeaderStyle, "flex flex-wrap")}>{text}</div>

    <Button
      height="sm"
      theme="slate"
      className="min-w-[112px]"
      onClick={openModal}
    >
      Let us know!
    </Button>
  </div>
);
