export enum Env {
  DEV = "development",
  TEST = "test",
}

const cookieDomain =
  getEnv() === "development"
    ? "localhost"
    : process.env.NEXT_PUBLIC_WEB_COOKIE_DOMAIN;

const legacyCookieDomain =
  getEnv() === "development"
    ? "localhost"
    : process.env.NEXT_PUBLIC_WEB_COOKIE_DOMAIN_LEGACY;

const cookieSecure = getEnv() === "development" ? false : true;

export const config = {
  env: getEnv(),

  cookieDomain,

  // Remove me in/after March 2024, please.
  legacyCookieDomain,
  cookieSecure,

  cognito: {
    REGION: process.env.NEXT_PUBLIC_REGION,
  },

  s3: {
    BUCKET: process.env.NEXT_PUBLIC_RECORDINGS_BUCKET_NAME,
    IDENTITY_POOL_ID: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
  },

  cognitoAuth: {
    mandatorySignIn: true,
    region: process.env.NEXT_PUBLIC_REGION,
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
    cookieStorage: {
      domain: cookieDomain,
      secure: cookieSecure,
      path: "/",
      expires: 365,
    },
  },

  cognitoVisitorAuth: {
    mandatorySignIn: true,
    userPoolId: process.env.NEXT_PUBLIC_USER_VISITOR_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_VISITOR_POOL_CLIENT_ID,
    cookieStorage: {
      domain: cookieDomain,
      secure: cookieSecure,
      path: "/",
      expires: 365,
    },
  },

  aiAssistantAuth: {
    password: process.env.NEXT_PUBLIC_QA_APP_PASSWORD,
  },
};

function getEnv() {
  if (process.env.NODE_ENV === "test") return Env.TEST;

  return process.env.NODE_ENV !== null && process.env.NODE_ENV !== undefined
    ? process.env.NODE_ENV
    : Env.DEV;
}
