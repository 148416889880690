import { tableTitleTextStyles } from "@utils/styleStrings";
import clsx from "clsx";
import {
  ATTENDANCE_MIN_WIDTH,
  ATTENDANCE_READONLY_WIDTH,
  GRADING_MIN_WIDTH,
  GRADING_READONLY_WIDTH,
  NOTES_MIN_WIDTH,
  SCREEN_WIDTH,
  STUDENT_WIDTH,
} from "../";

type Props = { readOnly: boolean; isGradingEnabled: boolean };

export const SessionStudentEvaluationTableHeader = ({
  readOnly,
  isGradingEnabled,
}: Props) => (
  <div
    className={clsx(
      "flex items-center w-full h-[36px] bg-slate-100/80 rounded-t-lg",
      tableTitleTextStyles
    )}
  >
    <div className={clsx("flex pl-4")} style={{ width: `${STUDENT_WIDTH}px` }}>
      Student
    </div>

    {readOnly && (
      <div
        className="flex justify-center"
        style={{ width: `${SCREEN_WIDTH}px` }}
      >
        Screen Name
      </div>
    )}

    <div
      className={clsx("flex justify-center", !readOnly && "flex-1")}
      style={{
        minWidth: `${
          readOnly ? ATTENDANCE_READONLY_WIDTH : ATTENDANCE_MIN_WIDTH
        }px`,
        width: readOnly ? `${ATTENDANCE_READONLY_WIDTH}px` : "w-auto",
      }}
    >
      <span className={clsx(!readOnly && "pr-[384px]")}>Attendance</span>
    </div>

    {isGradingEnabled && (
      <div
        className={clsx("flex", !readOnly && "flex-1")}
        style={{
          minWidth: `${
            readOnly ? GRADING_READONLY_WIDTH : GRADING_MIN_WIDTH
          }px`,
          width: readOnly ? `${GRADING_READONLY_WIDTH}px` : "auto",
        }}
      >
        Grades
      </div>
    )}

    {readOnly && (
      <div className="flex flex-1" style={{ minWidth: `${NOTES_MIN_WIDTH}px` }}>
        Notes
      </div>
    )}
  </div>
);
