import { ApolloError } from "@apollo/client";
import { triggerErrorToast } from "components/shared";

export const onError = (error: ApolloError) => console.error("Error: ", error);

export const errorToast = (
  entity: string,
  error?: ApolloError | string,
  errorType = "fetch"
) => {
  const errorMessage = error instanceof ApolloError ? error.message : error;

  triggerErrorToast({
    message: "Looks like something went wrong.",
    sub: `We weren't able to ${errorType} ${entity}`,
    log: errorMessage,
  });
};

export const fetchErrToast = (
  fetchEntity: string,
  error?: ApolloError | string
) => errorToast(fetchEntity, error, "fetch");

export const addErrToast = (
  fetchEntity: string,
  error?: ApolloError | string
) => errorToast(fetchEntity, error, "add");

export const editErrToast = (
  fetchEntity: string,
  error?: ApolloError | string
) => errorToast(fetchEntity, error, "update");

export const deleteErrToast = (
  fetchEntity: string,
  error?: ApolloError | string
) => errorToast(fetchEntity, error, "delete");
