import clsx from "clsx";
import { useState } from "react";
import { Icon } from "../Icon";

type Props = {
  copyValue: string;
  label?: string;
  iconSize?: number;
  disabled?: boolean;
};

export const MinimalCopyButton = ({
  copyValue,
  iconSize = 6,
  label = "",
  disabled = false,
}: Props) => {
  const [showSuccess, setShowSuccess] = useState(false);

  const handleClick = () => {
    if (disabled) return;
    navigator.clipboard.writeText(copyValue);
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 600);
  };

  const iconColor = disabled
    ? "text-gray-300"
    : showSuccess
    ? "text-emerald-700"
    : "text-gray-500 hover:text-gray-800";

  return (
    <button
      disabled={disabled}
      className={clsx(
        "relative flex items-center",
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      )}
      data-bs-toggle="tooltip"
      onClick={handleClick}
    >
      <Icon
        size={iconSize}
        icon={showSuccess ? "copySuccess" : "copy"}
        color={iconColor}
      />

      <div
        className={clsx(
          "text-slate-400 text-xs",
          "absolute transition left-[-10px] w-fit h-fit",
          showSuccess ? "top-[-17px] opacity-1" : "top-[-30px] opacity-0"
        )}
      >
        Copied
      </div>
      <span className="ml-1 text-xs text-gray-500">{label}</span>
    </button>
  );
};
