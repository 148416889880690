import { Menu, Transition } from "@headlessui/react";
import { Fragment, ReactNode, useState } from "react";

import { DotsHorizontalIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import noop from "lodash/noop";
import { usePopper } from "react-popper";

export function DotsMenu({ children }: { children: ReactNode }) {
  // Using popper to address this issue: https://github.com/tailwindlabs/tailwindui-issues/issues/147
  const [refElement, setRefElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(refElement, popperElement);

  return (
    <Menu>
      <Menu.Button
        ref={setRefElement}
        className={clsx(
          "flex flex-center rounded-full h-[23px] w-[23px] m-[-6px]",
          "text-slate-400 hover:text-white hover:bg-slate-500",
          "hover:border-slate-600/20 bg-gray-200/70 border border-gray-200/80",
          "focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
        )}
        onClick={() => (update ? update() : noop())}
      >
        <span className="sr-only">Open options</span>
        <DotsHorizontalIcon className="w-[17px] h-[17px]" aria-hidden="true" />
      </Menu.Button>

      <div
        style={styles.popper}
        {...attributes.popper}
        ref={setPopperElement}
        className="z-10"
      >
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100 "
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {children}
        </Transition>
      </div>
    </Menu>
  );
}
