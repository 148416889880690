import { gql, useMutation } from "@apollo/client";
import {
  EditUserReferralSourceModalBodyMutation,
  EditUserReferralSourceModalBodyMutationVariables,
  UserReferralSource,
  UserReferralSourceCard_UserFragment,
} from "@generated/graphql";
import { getUserReferralSourceText } from "@utils/labels";
import { sortReferralSourceOptions } from "@utils/sort";
import clsx from "clsx";
import { Button, Checkbox, ErrorBox, Input, Modal } from "components/shared";
import { useState } from "react";
import {
  itemContentStyle,
  itemSectionHeaderStyle,
  listContentStyle,
} from "sections/Profile/constants";

const EDIT_USER_REFERRAL_SOURCE_MUTATION = gql`
  mutation EditUserReferralSourceModalBody($input: EditUserPersonalInfoInput!) {
    editUserPersonalInfo(input: $input) {
      id
      referralName
      referralSources
      otherReferralSource
    }
  }
`;

type Props = {
  user: UserReferralSourceCard_UserFragment;
  closeModal: () => void;
};

export function UserReferralSourceModalBody({ user, closeModal }: Props) {
  const { Other, CurrentTeacher } = UserReferralSource;
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [referralName, setReferralName] = useState(user.referralName);
  const [referralSources, setReferralSources] = useState(user.referralSources);

  const [otherReferralSource, setOtherReferralSource] = useState(
    user.otherReferralSource
  );

  const hasOtherReferral = referralSources.includes(Other);
  const hasTeacherReferral = referralSources.includes(CurrentTeacher);

  const [editUserReferralSource, { loading }] = useMutation<
    EditUserReferralSourceModalBodyMutation,
    EditUserReferralSourceModalBodyMutationVariables
  >(EDIT_USER_REFERRAL_SOURCE_MUTATION, {
    onCompleted: closeModal,
    onError: (err) => setErrorMsg(err.message),
  });

  const onEditReferralSource = async () =>
    await editUserReferralSource({
      variables: {
        input: {
          id: user.id,
          referralSources,
          referralName: hasTeacherReferral ? referralName : "",
          otherReferralSource: hasOtherReferral ? otherReferralSource : "",
        },
      },
    });

  return (
    <>
      <div className="flex-center w-full h-fit">
        <div className="flex flex-col w-fit gap-y-[2px]">
          {Object.values(UserReferralSource)
            .sort(sortReferralSourceOptions)
            .map((referralSource) => {
              const isChecked = referralSources.includes(referralSource);
              const referralText = getUserReferralSourceText(referralSource);

              return (
                <div key={referralText} className="flex flex-col">
                  <div
                    className="flex items-center gap-x-3 w-full cursor-pointer"
                    onClick={() =>
                      setReferralSources((prevSources) =>
                        prevSources.includes(referralSource)
                          ? prevSources.filter(
                              (source) => source !== referralSource
                            )
                          : [...prevSources, referralSource]
                      )
                    }
                  >
                    <Checkbox checked={isChecked} />
                    <div className={listContentStyle}>{referralText}</div>
                  </div>

                  {referralSource === Other && hasOtherReferral && (
                    <Input
                      className="mt-1"
                      id="referralName"
                      value={otherReferralSource ?? ""}
                      placeholder="Please specify your other referral source"
                      onChange={(e) => setOtherReferralSource(e.target.value)}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>

      {hasTeacherReferral && (
        <div className="flex flex-col mt-4 mb-2">
          <div className={itemSectionHeaderStyle}>
            Who referred you?
            <div className={clsx(itemContentStyle, "mb-1")}>
              Please type their full name below so we can thank them.
            </div>
            <Input
              id="referralName"
              value={referralName ?? ""}
              className={itemContentStyle}
              onChange={(e) => setReferralName(e.target.value)}
            />
          </div>
        </div>
      )}

      <ErrorBox msg={errorMsg} />

      <Modal.Buttons className="!mt-0">
        <Button type="submit" loading={loading} onClick={onEditReferralSource}>
          Save
        </Button>
        <Modal.Button type="cancel" onClick={closeModal}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </>
  );
}
