import { gql } from "@apollo/client";
import { CompletedAgreementModalBody_CompletedAgreementFragment } from "@generated/graphql";
import { AutoWidthIFrame, Modal } from "components/shared";
import { PromptResponse } from "./PromptResponse";

CompletedAgreementModalBody.fragments = {
  completedAgreement: gql`
    fragment CompletedAgreementModalBody_CompletedAgreement on CompletedAgreement {
      document {
        fileName
        title
        revision
      }
      response {
        id
        signedAt
        responses {
          index
          ...PromptResponse_AgreementUserResponse
        }
      }
    }
    ${PromptResponse.fragments.agreementUserResponse}
  `,
};

type Props = {
  completedAgreement: CompletedAgreementModalBody_CompletedAgreementFragment;
  onClose(): void;
};

export function CompletedAgreementModalBody({
  completedAgreement,
  onClose,
}: Props) {
  const { fileName, revision, title } = completedAgreement.document;
  const { signedAt, responses } = completedAgreement.response;
  return (
    <div className="flex flex-col gap-y-8">
      <AutoWidthIFrame
        src={`/agreements/${encodeURIComponent(fileName)}/${encodeURIComponent(
          revision
        )}/agreement.html`}
        title={title}
        height={450}
      />
      <div className="flex flex-col gap-y-4 px-8">
        <h2 className="text-lg text-gray-800 font-medium">Recorded Response</h2>

        <div className="flex flex-col gap-y-1 pl-2">
          {responses.map((response) => (
            <PromptResponse
              key={response.index}
              agreementUserResponse={response}
            />
          ))}
        </div>
        <h3 className="italic text-gray-700 text-sm">
          (Received: {new Date(signedAt).toLocaleString()})
        </h3>
      </div>
      <Modal.Buttons>
        <Modal.Button type="confirm" onClick={onClose} loading={false}>
          Close
        </Modal.Button>
      </Modal.Buttons>
    </div>
  );
}
