import { clsx } from "clsx";
import { SelectMenu, SelectMenuOption, Tooltip } from "components/shared";
import {
  RoboDisplayNameLabel,
  SavedDisplayNameLabel,
} from "components/shared/AttendanceGrades/SpecialLabels";
import { StudentLiveParticipantMatch } from "components/shared/AttendanceGrades/types";
import { useEffect, useMemo } from "react";
import { NO_MATCH, NO_MATCH_OPTION } from "./constants";

type Props = {
  disabled: boolean;
  backupStudentName?: string | null;
  savedDisplayName?: string | null;
  matchData: StudentLiveParticipantMatch | undefined;
  onSave: (option: SelectMenuOption) => void;
};

export const DisplayNameSaveOnChangeInput = ({
  disabled,
  matchData,
  backupStudentName,
  savedDisplayName,
  onSave,
}: Props) => {
  const displayName = useMemo(
    () => savedDisplayName || backupStudentName || matchData?.match || null,
    [savedDisplayName, backupStudentName, matchData]
  );

  const studentMatchOptions = useMemo(() => {
    if (disabled) return [NO_MATCH_OPTION];
    const options: SelectMenuOption[] = (matchData?.distances ?? [])
      .filter((distance) => !!distance.participant)
      .map((distance, index) => {
        const isSelected = matchData?.match === distance.participant;
        const isSaved = savedDisplayName === distance.participant;

        return {
          id: `${distance.participant}_${index}`,
          value: distance.participant,
          ...((isSelected || isSaved) && {
            selectedLabel: isSaved ? (
              <SavedDisplayNameLabel displayName={distance.participant} />
            ) : (
              <RoboDisplayNameLabel displayName={distance.participant} />
            ),
            subValue: isSelected ? "(Robo suggestion)" : "(Saved display name)",
          }),
        };
      });

    const hasSavedDBOption = options.some(({ value }) => value === displayName);

    if (displayName && !hasSavedDBOption) {
      const forcedOption: SelectMenuOption = {
        id: "forcedOption",
        value: displayName,
        selectedLabel: <SavedDisplayNameLabel displayName={displayName} />,
        subValue: "(Saved display name)",
      };
      return [
        ...(backupStudentName !== NO_MATCH ? [NO_MATCH_OPTION] : []),
        forcedOption,
        ...options,
      ];
    }
    return [NO_MATCH_OPTION, ...options];
  }, [
    backupStudentName,
    disabled,
    displayName,
    matchData?.distances,
    matchData?.match,
    savedDisplayName,
  ]);

  const initialIndex =
    studentMatchOptions.findIndex(({ value }) => value === displayName) || -1;

  // Refills input with robo suggestion when transitioning away from disabled
  useEffect(() => {
    if (
      !disabled &&
      matchData?.match &&
      !savedDisplayName &&
      !backupStudentName
    ) {
      const matchedOption = studentMatchOptions.find(
        ({ value }) => value === matchData?.match
      );

      if (matchedOption) onSave(matchedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchData]);
  return (
    <div className="flex items-center">
      <SelectMenu
        disabled={disabled}
        listAlignment="right"
        className="min-w-[18ch]"
        options={studentMatchOptions}
        initialIndex={initialIndex < 0 ? undefined : initialIndex}
        onSelect={(option) => onSave(option)}
      />
      <Tooltip
        tooltipProps={{ place: "bottom" }}
        className={clsx("w-5 h-5 text-blue-600 ml-2 cursor-pointer")}
        content={
          <div className="block w-36 text-sm text-center">
            Select student screen name during the video session
          </div>
        }
      />
    </div>
  );
};
