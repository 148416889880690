import {
  CohortAssignmentSubject,
  DayOfWeek,
  RosterRole,
  TutoringPreferencesPosition,
} from "@generated/graphql";
import { dayOfWeekArray } from "@utils/dateTime";
import {
  getCohortSubjectText,
  getDayOfWeekText,
  getRosterRoleText,
} from "@utils/labels";
import { getGradeString } from "@utils/strings";
import { PillOption } from "components/shared/Inputs/PillSelectInput";
import {
  gradeOptions,
  testPrepSubjectOptions,
} from "sections/Profile/constants";

const { TutorTeacher: Tutor, SubstituteTeacher: Sub } = RosterRole;
const { InstructionalSupport, TeacherTutor } = TutoringPreferencesPosition;

export const positionPillOptions: PillOption<TutoringPreferencesPosition>[] = [
  { value: TeacherTutor, content: getRosterRoleText(Tutor) },
  { value: InstructionalSupport, content: getRosterRoleText(Sub) },
];

export const gradePillOptions: PillOption<string>[] = gradeOptions.map(
  (grade) => ({
    value: grade,
    content: getGradeString(grade, undefined, false, true),
  })
);

export const testPrepPillOptions: PillOption<CohortAssignmentSubject>[] =
  testPrepSubjectOptions.map((subject) => ({
    value: subject,
    content: getCohortSubjectText(subject, "long"),
  }));

export const dayOfWeekPillOptions: PillOption<DayOfWeek>[] = dayOfWeekArray.map(
  (dayOfWeek) => ({
    value: dayOfWeek,
    content: getDayOfWeekText(dayOfWeek, "compact"),
  })
);
