import { gql } from "@apollo/client";
import { SignAgreementsContent_CompletedAgreementFragment } from "@generated/graphql";
import clsx from "clsx";
import { useState } from "react";
import {
  itemContentStyle,
  itemSubContentStyle,
} from "sections/Profile/constants";
import { CompletedAgreementModal } from "./CompletedAgreementModal";

SignAgreementsContent.fragments = {
  completedAgreement: gql`
    fragment SignAgreementsContent_CompletedAgreement on CompletedAgreement {
      document {
        title
        revision
      }
      response {
        id
        signedAt
      }
      ...CompletedAgreementModal_CompletedAgreement
    }
    ${CompletedAgreementModal.fragments.completedAgreement}
  `,
};

type Props = {
  hasPendingAgreements: boolean;
  completedAgreements: SignAgreementsContent_CompletedAgreementFragment[];
};

export function SignAgreementsContent({
  completedAgreements,
  hasPendingAgreements,
}: Props) {
  const [selectedAgreement, setSelectedAgreement] =
    useState<SignAgreementsContent_CompletedAgreementFragment | null>(null);

  return (
    <div className={clsx(itemContentStyle, "flex flex-col gap-y-4")}>
      {hasPendingAgreements && (
        <p>
          You have pending agreements to sign. Try refreshing your browser to
          view them.
        </p>
      )}

      {completedAgreements.length === 0 && !hasPendingAgreements ? (
        <p className="text-sm text-gray-700">You&apos;re all set for now!</p>
      ) : (
        completedAgreements.map((agreement) => (
          <div
            key={`${agreement.document.fileName}-${agreement.document.revision}`}
            className={clsx(itemSubContentStyle)}
          >
            <p className="text-wrap">
              <span className="font-semibold">{agreement.document.title}</span>{" "}
              (
              <span className="italic text-gray-600">
                rev. {agreement.document.revision}
              </span>
              )
            </p>
            <p className="pl-3">
              Signed on {new Date(agreement.response.signedAt).toLocaleString()}
            </p>
            <p className="pl-3">
              <span
                className="text-blue-400 hover:text-blue-600 underline cursor-pointer"
                onClick={() => setSelectedAgreement(agreement)}
              >
                View Agreement
              </span>
            </p>
          </div>
        ))
      )}

      {selectedAgreement && (
        <CompletedAgreementModal
          completedAgreement={selectedAgreement}
          onClose={() => setSelectedAgreement(null)}
        />
      )}
    </div>
  );
}
