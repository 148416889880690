import { Listbox, Transition } from "@headlessui/react";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import isString from "lodash/isString";
import { useEffect, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import { FieldError } from "./Error";
import { Icon } from "./Icon";

export type MultiSelectMenuOption<O = unknown> = O & {
  id: string;
  label: React.ReactNode;
  subLabel?: React.ReactNode;
  selectedLabel?: React.ReactNode;
  disabled?: boolean;
};

type Props<O> = {
  labelText?: string;
  labelInsert?: React.ReactNode;
  description?: React.ReactNode;
  placeholder?: string;
  options: MultiSelectMenuOption<O>[];
  selectedIds: string[];
  onChange: (selectedOptions: MultiSelectMenuOption<O>[]) => void;
  className?: string;
  listWidth?: string;
  listHeight?: string;
  listAlignment?: "left" | "right";
  wordWrap?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: string;
};

/**
 * Note: Unlike SelectMenu this component does not maintain state for selected
 * values and must be controlled by the parent component.
 */
export function MultiSelectMenu<O>({
  labelText,
  labelInsert,
  description,
  placeholder = "Select...",
  options,
  selectedIds,
  onChange,
  className,
  listWidth = "w-auto",
  listHeight = "h-[500px]",
  listAlignment = "left",
  wordWrap = false,
  required = false,
  disabled = false,
  error,
}: Props<O>) {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes, update } = usePopper(refElement, popperElement, {
    placement: listAlignment === "right" ? "bottom-end" : "bottom-start",
  });
  useEffect(() => {
    update?.();
  }, [options, update]);

  const selectedOptions = useMemo(
    () => options.filter((option) => selectedIds.includes(option.id)),
    [options, selectedIds]
  );

  return (
    <div className={clsx("flex flex-col gap-0.5", className)}>
      <Listbox
        ref={setRefElement}
        value={selectedOptions}
        onChange={onChange}
        disabled={disabled}
        multiple
      >
        {({ open }) => (
          <div className="flex flex-col gap-1">
            {labelText && (
              <Listbox.Label className="flex flex-row flex-nowrap gap-x-1 text-sm font-medium text-gray-700">
                {labelText}
                {required && (
                  <span className="text-gray-500 text-sm font-light">*</span>
                )}
                {labelInsert}
              </Listbox.Label>
            )}
            <Listbox.Button
              className={clsx(
                "bg-white relative border border-gray-300 rounded-md shadow-sm",
                "pl-3 pr-10 sm:text-sm w-full flex items-center justify-between !min-h-[38px]",
                disabled ? "opacity-60 cursor-not-allowed" : "cursor-pointer"
              )}
              onClick={() => update?.()}
            >
              <div
                className={clsx(
                  "text-start",
                  wordWrap ? "text-wrap" : "truncate",
                  selectedIds.length === 0 && "italic text-gray-500"
                )}
              >
                {selectedOptions
                  .map((option) => option.selectedLabel || option.label)
                  .join(", ") || placeholder}
              </div>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <Icon icon="select" size={5} color="text-gray-600" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={"div"}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              style={styles.popper}
              {...attributes.popper}
              ref={setPopperElement}
              className="z-20"
            >
              <Listbox.Options
                className={clsx(
                  "absolute min-w-1 overflow-hidden shadow-xl mt-1 rounded-md",
                  listWidth,
                  listAlignment === "left" ? "left-0" : "right-0"
                )}
              >
                <div
                  className={clsx(
                    "relative border rounded-md",
                    "text-base overflow-auto sm:text-sm h-fit bg-gray-50 border-gray-200",
                    getScrollbarStyle("gray"),
                    listHeight
                  )}
                >
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      value={option}
                      className={({ active }) =>
                        clsx(
                          "cursor-pointer select-none relative py-2 pl-3 pr-9",
                          active ? "bg-blue-600" : "odd:bg-gray-100/80",
                          active && selectedIds && "bg-blue-700"
                        )
                      }
                      disabled={option.disabled}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex flex-col relative">
                            <span
                              className={clsx(
                                option.disabled &&
                                  "opacity-95 text-slate-300 text-left",
                                selected ? "font-semibold" : "font-normal",
                                active ? "text-white" : "text-gray-900"
                              )}
                            >
                              {option.label}
                            </span>
                            {option.subLabel && (
                              <span
                                className={clsx(
                                  selected ? "font-normal" : "font-light",
                                  active ? "text-white" : "text-gray-500",
                                  "block truncate"
                                )}
                              >
                                {option.subLabel}
                              </span>
                            )}
                          </div>
                          <span
                            className={clsx(
                              "absolute inset-y-0 right-0 flex items-center mr-2"
                            )}
                          >
                            <div
                              className={clsx(
                                "flex flex-center w-[14px] h-[14px] rounded-full"
                              )}
                            >
                              <Icon
                                icon="check"
                                size={5}
                                color={clsx(
                                  selected
                                    ? active
                                      ? "text-white"
                                      : "text-blue-600"
                                    : active
                                    ? "text-gray-500"
                                    : "text-gray-300"
                                )}
                              />
                            </div>
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </div>
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
      <FieldError msg={error} />
      {description && (
        <div className={clsx("ml-1", isString(description) && "text-sm")}>
          {description}
        </div>
      )}
    </div>
  );
}
