import { gql } from "@apollo/client";
import {
  AgreementDocumentPromptType,
  PromptResponse_AgreementUserResponseFragment,
} from "@generated/graphql";
import { assertUnreachable } from "@utils/types";

PromptResponse.fragments = {
  agreementUserResponse: gql`
    fragment PromptResponse_AgreementUserResponse on AgreementUserResponse {
      promptType
      promptText
      promptRequired
      value
    }
  `,
};

type Props = {
  agreementUserResponse: PromptResponse_AgreementUserResponseFragment;
};

export function PromptResponse({
  agreementUserResponse: { promptType, promptText, promptRequired, value },
}: Props) {
  switch (promptType) {
    case AgreementDocumentPromptType.Checkbox:
      return (
        <p>
          <span className="italic">{promptText}</span> &ndash; &quot;
          <span className="font-light capitalize">{value.toLowerCase()}</span>
          &quot;
          {!promptRequired && (
            <span className="italic text-gray-500"> (optional)</span>
          )}
        </p>
      );
    case AgreementDocumentPromptType.Signature:
      return (
        <div className="flex flex-col gap-y-1">
          <p className="text-gray-700 text-base">
            <span className="italic">Signed</span> &ndash;{" "}
            {value ? (
              <>
                &quot;
                <span className="font-light">{value}</span>
                &quot;
              </>
            ) : (
              <>
                [<span className="font-mono">BLANK</span>]
              </>
            )}
            {!promptRequired && (
              <span className="italic text-gray-500"> (optional)</span>
            )}
          </p>
        </div>
      );
    default:
      assertUnreachable(promptType, "promptType");
  }
}
