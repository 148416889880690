import clsx from "clsx";
import { Badge } from "./Badge";

type Props = { id: string };

export const IDBadge = ({ id }: Props) => (
  <Badge
    className={clsx(
      "inline-flex flex-center rounded-full px-2 py-[2px] text-center text-xs bg-slate-400 gap-0.5"
    )}
  >
    <span className="font-semibold text-white">ID:</span>
    <span className="font-medium text-white">{id}</span>
  </Badge>
);
