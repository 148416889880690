import clsx from "clsx";
import { useState } from "react";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";

export type Filter = {
  name: string;
  selected: boolean;
  show?: boolean;
  tooltip: React.ReactNode;
  onClick: (newState: boolean) => void;
};

type Props = { filters: Filter[] };

export const Filters = ({ filters }: Props) => {
  const [showFilters, setShowFilters] = useState(true);

  const renderFilter = (
    { name, selected, tooltip, onClick }: Filter,
    isLiveFilter: boolean
  ) => (
    <Tooltip
      key={name}
      tooltipProps={{ delayShow: 300 }}
      content={
        <div className="flex flex-col w-fit flex-wrap text-center max-w-[240px] z-[4000] px-3">
          {tooltip}
        </div>
      }
    >
      <div
        className={clsx(
          "flex flex-center h-7 w-fit z-[60]",
          "rounded-lg cursor-pointer z-70 overflow-hidden relative",
          !isLiveFilter && selected && "bg-slate-500 text-white",
          !isLiveFilter && !selected && "bg-slate-100 hover:bg-slate-200",
          isLiveFilter && selected && "bg-indigo-200 text-white/90",
          isLiveFilter && !selected && "bg-white text-slate-700"
        )}
        onClick={() => onClick(!selected)}
      >
        {isLiveFilter && (
          <>
            <div className="absolute flex flex-center h-full w-full z-10 overflow-hidden px-3">
              <div className={"liveButtonBg opacity-[0.9] "} />
            </div>
            <div
              className={clsx(
                "absolute flex top-[2px] left-[2px] h-[calc(100%-4px)] w-[calc(100%-4px)] z-[20] rounded-md",
                selected ? "bg-indigo-800/[0.73]" : "bg-slate-50/[0.83]"
              )}
            />
          </>
        )}
        <div
          className={clsx(
            "flex flex-center h-7 w-full z-[60] rounded-lg px-3",
            isLiveFilter && selected && "border border-indigo-800/[0.2]",
            isLiveFilter && !selected && "border border-slate-400/[0.3]"
          )}
        >
          <p className="z-[40]">{name}</p>
        </div>
      </div>
    </Tooltip>
  );

  return (
    <div className="relative flex items-center justify-end h-full gap-x-[6px] text-sm text-slate-700 z-[60]">
      {showFilters &&
        filters
          .filter(({ show }) => show ?? true)
          .map((filter) => renderFilter(filter, filter.name === "Live"))}
      <div
        className="cursor-pointer"
        onClick={() => setShowFilters(!showFilters)}
      >
        <Icon icon="filters" color="text-slate-500" />
      </div>
    </div>
  );
};
