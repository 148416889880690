import { gql } from "@apollo/client";
import { PersonalInformationChecklistItem_UserFragment } from "@generated/graphql";
import clsx from "clsx";
import { ChecklistItem } from "components/shared";
import { useState } from "react";
import {
  itemContentStyle,
  itemSubContentStyle,
} from "sections/Profile/constants";
import { EditUserPersonalInfoModal } from "../..";

PersonalInformationChecklistItem.fragments = {
  user: gql`
    fragment PersonalInformationChecklistItem_User on User {
      id
      phoneNumber
      countryId
      stateId
      timeZone
    }
  `,
};

type Props = {
  userId: string;
  user: PersonalInformationChecklistItem_UserFragment | null | undefined;
  refetch: () => void;
};

export function PersonalInformationChecklistItem({
  userId,
  user,
  refetch,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  const hasPhoneNumber = !!user?.phoneNumber;
  const hasCountry = !!user?.countryId;
  const hasState = !!user?.stateId;
  const hasTimeZone = !!user?.timeZone;

  const stateRequired =
    user?.countryId === null ||
    user?.countryId === "US" ||
    user?.countryId === "CA";

  let completeCount = 0;
  let incompleteCount = 0;
  hasPhoneNumber ? ++completeCount : ++incompleteCount;
  hasCountry ? ++completeCount : ++incompleteCount;
  if (stateRequired) hasState ? ++completeCount : ++incompleteCount;
  hasTimeZone ? ++completeCount : ++incompleteCount;

  const completionText =
    incompleteCount > 0
      ? `(${Math.floor(
          (completeCount / (completeCount + incompleteCount)) * 100
        )}% complete)`
      : "";

  return (
    <>
      <ChecklistItem
        loading={!user}
        trackedForCompletion
        complete={incompleteCount === 0}
        title={
          <div className="flex items-center gap-x-1">
            {`Fill out your`}
            <span
              className={clsx(
                "underline-offset-2 decoration-gray-400/90 cursor-pointer",
                "hover:decoration-blue-400",
                incompleteCount > 0 && "underline "
              )}
              onClick={() => setShowModal(true)}
            >
              Personal Information
            </span>
            {completionText}
          </div>
        }
        collapsibleContent={
          incompleteCount > 0 ? (
            <>
              <p className={clsx(itemContentStyle, "pb-1")}>
                To complete this item, you still need to fill out the following:
              </p>
              <ul
                className={clsx(
                  "list-disc list-inside *:ml-1",
                  itemSubContentStyle
                )}
              >
                {!hasPhoneNumber && <li>Phone number</li>}
                {!hasCountry && <li>Country of residence</li>}
                {stateRequired && !hasState && <li>State</li>}
                {!hasTimeZone && <li>Time Zone</li>}
              </ul>
            </>
          ) : null
        }
      />

      <EditUserPersonalInfoModal
        show={showModal}
        userId={userId}
        closeModal={() => {
          setShowModal(false);
          refetch();
        }}
      />
    </>
  );
}
