import { RosterRecordStatus } from "@generated/graphql";
import { getRosterRecordStatusText } from "@utils/labels";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = { rosterRecordStatus: RosterRecordStatus; className?: string };

export const RosterRecordStatusBadge = ({
  className,
  rosterRecordStatus,
}: Props) => (
  <Badge className={clsx(statusColors[rosterRecordStatus], className)}>
    {getRosterRecordStatusText(rosterRecordStatus)}
  </Badge>
);

const statusColors: { [key in RosterRecordStatus]: string } = {
  [RosterRecordStatus.Confirmed]: "text-emerald-900 bg-emerald-200",
  [RosterRecordStatus.Pending]: "text-orange-900 bg-orange-200",
  [RosterRecordStatus.Destaffed]: "text-rose-900 bg-rose-200",
};
