import { gql } from "@apollo/client";
import { TimeSlotRangeTooltip_TutoringPreferencesAvailabilityFragment } from "@generated/graphql";
import { IANAtzName, normalizeToLocalISODate } from "@utils/dateTime";
import clsx from "clsx";
import { Icon, ReactTooltipProps, Tooltip } from "components/shared";
import { renderContent } from "../helpers";
import { buildTimeRangeString } from "../utils";

TimeSlotRangeTooltip.fragments = {
  tutoringPreferencesAvailability: gql`
    fragment TimeSlotRangeTooltip_TutoringPreferencesAvailability on TutoringPreferencesAvailability {
      ...BuildTimeRangeString_TutoringPreferencesAvailability
    }
    ${buildTimeRangeString.fragments.tutoringPreferencesAvailability}
  `,
};

type TimeSlotRangeProps = {
  tutoringPreferencesAvailability: TimeSlotRangeTooltip_TutoringPreferencesAvailabilityFragment;
  className?: string;
  tooltipProps?: ReactTooltipProps;
  clientTimeZoneOverride?: IANAtzName;
};

export function TimeSlotRangeTooltip({
  className,
  tooltipProps,
  clientTimeZoneOverride: tzOverride,
  tutoringPreferencesAvailability: availability,
}: TimeSlotRangeProps) {
  const nowISODate = normalizeToLocalISODate(new Date());
  const clientTimeZone =
    tzOverride || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const tooltipContentText = buildTimeRangeString(
    availability,
    nowISODate,
    availability.timeZone,
    false
  );
  const tooltipChildText = buildTimeRangeString(
    availability,
    nowISODate,
    clientTimeZone,
    true
  );
  const tooltipDisabled = tooltipContentText === tooltipChildText;

  return (
    <Tooltip
      {...tooltipProps}
      disabled={tooltipDisabled}
      content={renderContent(tooltipContentText)}
      className={clsx(className, "flex items-center gap-x-1")}
    >
      {tooltipChildText}
      {!tooltipDisabled && (
        <Icon
          icon="infoCircle"
          size={3}
          color="text-blue-500"
          className="mb-2"
        />
      )}
    </Tooltip>
  );
}
