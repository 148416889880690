import { EngagementAssignmentRole } from "@generated/graphql";
import {
  EngagementAssignmentRoleTextMode,
  getEngagementAssignmentRoleText,
} from "@utils/labels";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = {
  role: EngagementAssignmentRole;
  mode?: EngagementAssignmentRoleTextMode;
  className?: string;
  teacherName?: string;
};

export const EngagementAssignmentRoleBadge = ({
  role,
  mode = "long",
  className,
  teacherName,
}: Props) => (
  <Badge className={clsx(roleColors[role], "text-center h-fit", className)}>
    {teacherName ?? getEngagementAssignmentRoleText(role, mode)}
  </Badge>
);

const roleColors: { [key in EngagementAssignmentRole]: string } = {
  [EngagementAssignmentRole.MentorTeacher]: "text-emerald-900 bg-emerald-200",
  [EngagementAssignmentRole.SubstituteTeacher]: "text-sky-900 bg-sky-200",
  [EngagementAssignmentRole.EngagementManager]: "text-orange-900 bg-orange-200",
};
