import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { PropsWithChildren } from "react";

type Props = { className?: string; noNav?: boolean; noScroll?: boolean };

export const Page = ({
  noNav,
  children,
  className,
  noScroll = false,
}: PropsWithChildren<Props>) => (
  <main
    className={clsx(
      "z-0",
      getScrollbarStyle("page"),
      noScroll ? "overflow-y-hidden" : "overflow-y-auto",
      noNav ? "h-screen" : "h-screen-nav"
    )}
  >
    <div
      className={clsx(
        "flex flex-col gap-y-4 justify-between w-auto p-4",
        className
      )}
    >
      {children}
    </div>
  </main>
);
