import { gql, useQuery } from "@apollo/client";
import { GetAttendanceScorecardQuery } from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { getUserPronounsText } from "@utils/labels";
import { subMonths } from "date-fns";
import { useState } from "react";
import { OptionsToggleButton } from "../../Buttons";
import { EmptyStateContainer } from "../../EmptyStateContainer";
import { Icon } from "../../Icon";
import { LoadingTableSkeleton } from "../../Loading";
import { Modal } from "../../Modal";
import { AttendanceScorecardTable } from "./components/AttendanceScorecardTable";

export enum TeacherScorecardPeriod {
  lastSixMonths = "Last 6 Months",
  allTime = "All Time",
}

const ATTENDANCE_SCORECARD_QUERY = gql`
  query GetAttendanceScorecard($userId: ID!) {
    getAttendanceScorecard(userId: $userId) {
      id
      name
      idap
      email
      pronouns
      phoneNumber
      attendanceScorecardRecords {
        ...AttendanceScorecardTable_Teachers
      }
    }
  }
  ${AttendanceScorecardTable.fragments.attendance}
`;

type Props = {
  show: boolean;
  userId: string;
  closeModal: () => void;
};

export const AttendanceScorecardModal = ({
  show,
  userId,
  closeModal,
}: Props) => {
  const [periodView, setPeriodView] = useState<number>(0);
  const sixMonthsAgo = subMonths(new Date(), 6);
  const allTime = periodView === 1;

  const { loading, data } = useQuery<GetAttendanceScorecardQuery>(
    ATTENDANCE_SCORECARD_QUERY,
    {
      skip: !userId,
      variables: { userId },
      fetchPolicy: "network-only",
      onError: (error) => fetchErrToast("the attendance data", error),
    }
  );

  const user = data?.getAttendanceScorecard;
  const { name, email, phoneNumber, idap } = user || {};
  const { attendanceScorecardRecords, pronouns } = user || {};

  const pastSixMonthsScorecards = attendanceScorecardRecords?.filter(
    (scorecard) => +new Date(scorecard.sessionDate) > +sixMonthsAgo
  );

  const displayedScorecards = allTime
    ? attendanceScorecardRecords
    : pastSixMonthsScorecards;

  const hasScorecards = displayedScorecards && displayedScorecards.length > 0;

  return (
    <Modal
      show={show}
      onClose={closeModal}
      icon={<Modal.Icon icon="source" />}
      title={
        name ? (
          <div className="flex items-center gap-x-[3px]">
            {`Teacher Scorecard - ${name}`}
            <span className="font-medium text-gray-800 text-[12px] leading-none pt-[4px]">
              {getUserPronounsText(pronouns, "", true)}
            </span>
          </div>
        ) : (
          "Teacher Scorecard"
        )
      }
      subtitle="View attendance data for the selected teacher."
      actionButton={
        <div className="flex flex-col items-end text-sm font-semibold mr-1">
          {idap && <span>IDAP: {idap}</span>}
          {email && <span>{email}</span>}
          {phoneNumber && <span>{phoneNumber}</span>}
        </div>
      }
      width="xxxxlarge"
    >
      <div className="flex flex-col gap-4 my-4">
        <OptionsToggleButton
          options={Object.values(TeacherScorecardPeriod)}
          setActiveTab={setPeriodView}
          activeTab={periodView}
          height="h-[42px]"
          radius={8}
        />
        {loading && <LoadingTableSkeleton rows={4} />}
        {hasScorecards ? (
          <AttendanceScorecardTable attendanceRecords={displayedScorecards} />
        ) : (
          <EmptyStateContainer
            dataName="Attendance Data"
            icon={<Icon icon="attendance" size={10} />}
            subtitle="There is no noteworthy attendance data for the selected date range (i.e.
        no absences and no notes)."
          />
        )}
      </div>

      <Modal.Buttons>
        <Modal.Button type="cancel" onClick={closeModal}>
          Close
        </Modal.Button>
      </Modal.Buttons>
    </Modal>
  );
};
