import { gql } from "@apollo/client";
import {
  EducationDegree,
  TeachingExperienceCard_TeachingExperienceFragment,
} from "@generated/graphql";
import { getEducationDegreeText } from "@utils/labels";
import { Card } from "components/shared";
import {
  CardDetailsRow,
  getEditModalOpenHeader,
  HeaderColors,
} from "components/shared/Card";
import { useAuth } from "contexts/AuthProvider";
import { useState } from "react";
import { getNoDataCallToAction } from "sections/Profile/helpers";
import { AddEditTeachingExperienceModal } from "./components/AddEditTeachingExperienceModal";

TeachingExperienceCard.fragments = {
  teachingExperience: gql`
    fragment TeachingExperienceCard_TeachingExperience on TeachingExperience {
      id
      userId
      yearsOfExperience
      highestEducationDegree

      hasEslOrEllCertification
      hasTeflOrTesolCertification
      hasSpecialEducationCertification
      hasBilingualInstructionCertification

      hasApOrIbExperience
      hasSatOrActTestPrepExperience
      hasBilingualInstructionExperience
      hasCollegeAndCareerReadinessExperience
    }
  `,
};

type Props = {
  userId: string;
  className?: string;
  experience?: TeachingExperienceCard_TeachingExperienceFragment | null;
  refetch: () => void;
};

export function TeachingExperienceCard({
  userId,
  className,
  experience: xp,
  refetch,
}: Props) {
  const { user } = useAuth();
  const { NoDegreeInEducation } = EducationDegree;
  const [showModal, setShowModal] = useState(false);

  const certifications = [
    ...[xp?.hasEslOrEllCertification && "ESL/ELL"],
    ...[xp?.hasTeflOrTesolCertification && "TEFL/TESOL"],
    ...[xp?.hasSpecialEducationCertification && "Special Education"],
    ...[xp?.hasBilingualInstructionCertification && "Bilingual Instruction"],
  ]
    .filter(Boolean)
    .join(", ");

  const experience = [
    ...[xp?.hasApOrIbExperience && "AP/IB"],
    ...[xp?.hasSatOrActTestPrepExperience && "SAT/ACT Test Prep"],
    ...[xp?.hasBilingualInstructionExperience && "Bilingual Instruction"],
    ...[
      xp?.hasCollegeAndCareerReadinessExperience &&
        "College and Career Readiness",
    ],
  ]
    .filter(Boolean)
    .join(", ");

  const rows = xp
    ? ([
        [
          "Years of Professional Teaching Experience",
          xp?.yearsOfExperience ?? 0,
        ],
        [
          "Highest Degree in Education",
          getEducationDegreeText(
            xp?.highestEducationDegree ?? NoDegreeInEducation
          ),
        ],
        ["Additional Certifications", certifications],
        ["Other Experience", experience],
      ] as CardDetailsRow[])
    : undefined;

  return (
    <>
      <Card
        rows={rows}
        icon="requirements"
        className={className}
        headerOverlayColor={HeaderColors.Purple}
        header={getEditModalOpenHeader("Teaching Experience", () =>
          setShowModal(true)
        )}
      >
        {!xp &&
          getNoDataCallToAction(
            () => setShowModal(true),
            `Please let us know about your teaching experience.`
          )}
      </Card>

      <AddEditTeachingExperienceModal
        experience={xp}
        userId={userId}
        show={!!user?.id && showModal}
        closeModal={() => {
          setShowModal(false);
          refetch();
        }}
      />
    </>
  );
}
