import { AccountRole } from "@generated/graphql";
import { AdminMode } from "types/global";
import { RoomType } from "../roomUrls";
import {
  AllAccessPermitted,
  AllAdminModesPermitted,
  AllAuthedRolesPermitted,
} from "./constants";
import { Route } from "./types";

const { Staffing, Managing, Dashboard, ControlPanel } = AdminMode;
const { MentorTeacher, TutorTeacher, Admin, Visitor } = AccountRole;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Routes: any = {
  home: {
    href: () => "/",
    path: () => "/",
    adminModeAccess: [Dashboard],
    accountRoleAccess: [Admin, MentorTeacher, TutorTeacher, Visitor],
  } as Route,
  rerouting: {
    href: () => "/rerouting",
    path: () => "/rerouting",
    accountRoleAccess: AllAccessPermitted,
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  notFound: {
    href: () => "/404",
    path: () => "/404",
    accountRoleAccess: AllAccessPermitted,
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  authIssue: {
    href: () => "/auth-issue",
    path: () => "/auth-issue",
    accountRoleAccess: AllAccessPermitted,
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  login: {
    href: () => "/login",
    path: () => "/login",
    accountRoleAccess: AllAccessPermitted,
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  forgotPassword: {
    href: () => "/forgot-password",
    path: () => "/forgot-password",
    accountRoleAccess: AllAccessPermitted,
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  visitor: {
    href: (limitedAccessKey: string) =>
      `/visitor?limitedAccessKey=${limitedAccessKey}`,
    path: () => "/visitor",
    adminModeAccess: AllAdminModesPermitted,
    accountRoleAccess: AllAccessPermitted,
  } as Route,
  visitorRoster: {
    href: (engagementLimitedAccessKey: string) =>
      `/visitor/roster/${engagementLimitedAccessKey}`,
    path: () => "/visitor/roster/[engagementLimitedAccessKey]",
    adminModeAccess: AllAdminModesPermitted,
    accountRoleAccess: AllAuthedRolesPermitted,
  } as Route,
  magicLink: {
    href: (params: Record<string, string>) =>
      `/visitor/magic-link?${formatParams(params)}`,
    path: () => "/visitor/magic-link",
    accountRoleAccess: AllAccessPermitted,
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  organizationShortcut: {
    href: (organizationShortcut: string, externalStudentId?: string) =>
      externalStudentId
        ? `/${organizationShortcut}?externalStudentId=${externalStudentId}`
        : `/${organizationShortcut}`,
    path: (externalStudentId?: string) =>
      externalStudentId
        ? `/[organizationShortcut]?externalStudentId=${externalStudentId}`
        : `/[organizationShortcut]`,
    accountRoleAccess: AllAccessPermitted,
    adminModeAccess: AllAdminModesPermitted,
    public: true,
  } as Route,
  iCalendarImportPage: {
    href: (referenceId: string) => `/ical-import/${referenceId}`,
    path: () => "/ical-import/[referenceId]",
    accountRoleAccess: AllAccessPermitted,
    adminModeAccess: AllAdminModesPermitted,
    public: true,
  } as Route,
  meetingRoomPage: {
    href: (publicReferenceId: string, roomType: RoomType) =>
      `/meeting/${roomType}/${publicReferenceId}`,
    path: () => "/meeting/[roomType]/[publicReferenceId]",
    accountRoleAccess: AllAccessPermitted,
    adminModeAccess: AllAdminModesPermitted,
    public: true,
  } as Route,
  mySchedule: {
    href: () => "/my-schedule",
    path: () => "/my-schedule",
    adminModeAccess: AllAdminModesPermitted,
    accountRoleAccess: [MentorTeacher, TutorTeacher],
  } as Route,
  myPayments: {
    href: () => "/my-payments",
    path: () => "/my-payments",
    accountRoleAccess: [TutorTeacher],
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  myProfile: {
    href: () => "/my-profile",
    path: () => "/my-profile",
    accountRoleAccess: [TutorTeacher],
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  myPD: {
    href: (platformCourseCode?: string) =>
      `/my-pd${
        platformCourseCode ? `?platformCourseCode=${platformCourseCode}` : ""
      }`,
    path: () => "/my-pd",
    accountRoleAccess: [TutorTeacher],
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  triggers: {
    href: () => "/triggers",
    path: () => "/triggers",
    adminModeAccess: [Managing],
    accountRoleAccess: [Admin],
  } as Route,
  myAttendance: {
    href: () => "/my-attendance",
    path: () => "/my-attendance",
    accountRoleAccess: [TutorTeacher],
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  organizations: {
    href: () => "/organizations",
    path: () => "/organizations",
    accountRoleAccess: [Admin],
    adminModeAccess: [Managing, Staffing],
  } as Route,
  maintenanceJobs: {
    href: () => "/maintenance-jobs",
    path: () => "/maintenance-jobs",
    accountRoleAccess: [Admin],
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  workerServiceJobs: {
    href: () => "/worker-service-jobs",
    path: () => "/worker-service-jobs",
    accountRoleAccess: [Admin],
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  engagements: {
    href: () => "/engagements",
    path: () => "/engagements",
    adminModeAccess: [Managing, Staffing],
    accountRoleAccess: [MentorTeacher, TutorTeacher, Admin],
  } as Route,
  engagementsDashboard: {
    href: (params: string) => `/engagements?${params}`,
    path: () => "/engagements",
    accountRoleAccess: [MentorTeacher, TutorTeacher],
    adminModeAccess: AllAdminModesPermitted,
  } as Route,
  cohorts: {
    href: () => "/cohorts",
    path: () => "/cohorts",
    accountRoleAccess: [Admin],
    adminModeAccess: [Managing],
  } as Route,
  users: {
    href: () => "/users",
    path: () => "/users",
    accountRoleAccess: [Admin],
    adminModeAccess: [Managing, Staffing],
  } as Route,
  students: {
    href: () => "/students",
    path: () => "/students",
    adminModeAccess: [Managing],
    accountRoleAccess: [Admin],
  } as Route,
  recordings: {
    href: () => "/recordings",
    path: () => "/recordings",
    adminModeAccess: [Managing, Staffing],
    accountRoleAccess: [Admin],
  } as Route,
  settings: {
    usHolidays: {
      href: () => "/settings/us-holidays",
      path: () => "/settings/us-holidays",
      adminModeAccess: [ControlPanel],
      accountRoleAccess: [Admin],
    } as Route,
    slackMonitors: {
      href: () => "/settings/slack-monitors",
      path: () => "/settings/slack-monitors",
      adminModeAccess: [ControlPanel],
      accountRoleAccess: [Admin],
    } as Route,
    staffingRatios: {
      href: () => "/settings/staffing-ratios",
      path: () => "/settings/staffing-ratios",
      adminModeAccess: [ControlPanel],
      accountRoleAccess: [Admin],
    } as Route,
  },
  zoomClientConfiguration: {
    href: () => "/zoom-client-configuration",
    path: () => "/zoom-client-configuration",
    adminModeAccess: [ControlPanel],
    accountRoleAccess: [Admin],
  },
  qaApp: {
    href: () => "/qa-app",
    path: () => "/qa-app",
    adminModeAccess: [ControlPanel],
    accountRoleAccess: [Admin],
  } as Route,
  job: {
    board: {
      href: () => "/job/board",
      path: () => "/job/board",
      adminModeAccess: [Staffing],
      accountRoleAccess: [MentorTeacher, TutorTeacher, Admin],
    } as Route,
    teacherJobDetails: {
      href: (jobPostingId: string) =>
        `/job/${jobPostingId}/teacher-job-details`,
      path: () => "/job/[jobPostingId]/teacher-job-details",
      adminModeAccess: AllAdminModesPermitted,
      accountRoleAccess: [MentorTeacher, TutorTeacher],
    } as Route,
    staffingJobDetails: {
      href: (jobPostingId: string) =>
        `/job/${jobPostingId}/staffing-job-details`,
      path: () => "/job/[jobPostingId]/staffing-job-details",
      adminModeAccess: [Staffing],
      accountRoleAccess: [Admin],
    } as Route,
  },
  org: {
    details: {
      href: (orgId: string) => `/organizations/${orgId}/details`,
      path: () => "/organizations/[organizationId]/details",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    students: {
      href: (orgId: string) => `/organizations/${orgId}/students`,
      path: () => "/organizations/[organizationId]/students",
      adminModeAccess: [Managing],
      accountRoleAccess: [Admin],
    } as Route,
    visitors: {
      href: (orgId: string) => `/organizations/${orgId}/visitors`,
      path: () => "/organizations/[organizationId]/visitors",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    noTutoringDays: {
      href: (orgId: string) => `/organizations/${orgId}/no-tutoring-days`,
      path: () => "/organizations/[organizationId]/no-tutoring-days",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    engagements: {
      href: (orgId: string) => `/organizations/${orgId}/engagements`,
      path: () => "/organizations/[organizationId]/engagements",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    cohorts: {
      href: (orgId: string) => `/organizations/${orgId}/cohorts`,
      path: () => "/organizations/[organizationId]/cohorts",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    uploadStudentData: {
      href: (orgId: string) => `/organizations/${orgId}/upload-student-data`,
      path: () => "/organizations/[organizationId]/upload-student-data",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
  },
  engagement: {
    details: {
      href: (engagementId: string) => `/engagements/${engagementId}/details`,
      path: () => "/engagements/[engagementId]/details",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    roster: {
      href: (engagementId: string) => `/engagements/${engagementId}/roster`,
      path: () => "/engagements/[engagementId]/roster",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    shifts: {
      href: (engagementId: string) => `/engagements/${engagementId}/shifts`,
      path: () => "/engagements/[engagementId]/shifts",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    cohorts: {
      href: (engagementId: string) => `/engagements/${engagementId}/cohorts`,
      path: () => "/engagements/[engagementId]/cohorts",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    students: {
      href: (engagementId: string) => `/engagements/${engagementId}/students`,
      path: () => "/engagements/[engagementId]/students",
      adminModeAccess: [Managing],
      accountRoleAccess: [Admin],
    } as Route,
    schedule: {
      href: (engagementId: string) => `/engagements/${engagementId}/schedule`,
      path: () => "/engagements/[engagementId]/schedule",
      adminModeAccess: [Managing],
      accountRoleAccess: [Admin],
    } as Route,
    uploadCsv: {
      href: (engagementId: string) => `/engagements/${engagementId}/upload-csv`,
      path: () => "/engagements/[engagementId]/upload-csv",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    uploadStudentData: {
      href: (engagementId: string) =>
        `/engagements/${engagementId}/upload-student-data`,
      path: () => "/engagements/[engagementId]/upload-student-data",
      adminModeAccess: [Managing],
      accountRoleAccess: [Admin],
    } as Route,
    publicStudentPage: {
      href: (publicReferenceId: string) =>
        `/student/engagement/${publicReferenceId}`,
      path: () => "/student/engagement/[publicReferenceId]",
      accountRoleAccess: AllAccessPermitted,
      adminModeAccess: AllAdminModesPermitted,
      public: true,
    } as Route,
    publicSchoologyStudentPage: {
      href: (externalId: string, publicReferenceId: string) =>
        `/student/${externalId}/schoology/engagement/${publicReferenceId}`,
      path: () =>
        "/student/[externalId]/schoology/engagement/[publicReferenceId]",
      accountRoleAccess: AllAccessPermitted,
      adminModeAccess: AllAdminModesPermitted,
      public: true,
    } as Route,
    publicStudentAttendancePage: {
      href: (engagementLimitedAccessKey: string, gradingPeriodQuery?: string) =>
        `/student/attendance/${engagementLimitedAccessKey}${
          gradingPeriodQuery ? `?gradingPeriod=${gradingPeriodQuery}` : ""
        }`,
      path: () => "/student/attendance/[engagementLimitedAccessKey]",
      accountRoleAccess: AllAuthedRolesPermitted,
      adminModeAccess: AllAdminModesPermitted,
      public: true,
    } as Route,
    studentAttendance: {
      href: (engagementId: string, gradingPeriodQuery?: string) =>
        `/engagements/${engagementId}/student-attendance${
          gradingPeriodQuery ? `?gradingPeriod=${gradingPeriodQuery}` : ""
        }`,
      path: () => "/engagements/[engagementId]/student-attendance",
      adminModeAccess: [Managing],
      accountRoleAccess: [Admin, MentorTeacher],
    } as Route,
    studentGrades: {
      base: {
        href: (engagementId: string) =>
          `/engagements/${engagementId}/student-grades`,
        path: () => "/engagements/[engagementId]/student-grades",
        adminModeAccess: [Managing],
        accountRoleAccess: [Admin, MentorTeacher],
      } as Route,
      subjectSelected: {
        href: (
          engagementId: string,
          subjectComboId: string,
          gradingPeriodQuery?: string
        ) =>
          `/engagements/${engagementId}/student-grades/${subjectComboId}${
            gradingPeriodQuery ? `?gradingPeriod=${gradingPeriodQuery}` : ""
          }`,
        path: () =>
          "/engagements/[engagementId]/student-grades/[subjectComboId]",
        adminModeAccess: [Managing],
        accountRoleAccess: [Admin, MentorTeacher],
      } as Route,
    },
    publicStudentGrades: {
      base: {
        href: (engagementLimitedAccessKey: string) =>
          `/student/grades/${engagementLimitedAccessKey}`,
        path: () => "/student/grades/[engagementLimitedAccessKey]",
        accountRoleAccess: AllAuthedRolesPermitted,
        public: true,
      } as Route,
      subjectSelected: {
        href: (
          engagementLimitedAccessKey: string,
          subjectComboId: string,
          gradingPeriodQuery?: string
        ) =>
          `/student/grades/${engagementLimitedAccessKey}/${subjectComboId}${
            gradingPeriodQuery ? `?gradingPeriod=${gradingPeriodQuery}` : ""
          }`,
        path: () =>
          "/student/grades/[engagementLimitedAccessKey]/[subjectComboId]",
        accountRoleAccess: AllAuthedRolesPermitted,
        adminModeAccess: AllAdminModesPermitted,
        public: true,
      } as Route,
    },
    logs: {
      href: (engagementId: string) => `/engagements/${engagementId}/logs`,
      path: () => "/engagements/[engagementId]/logs",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
  },
  cohort: {
    details: {
      href: (cohortId: string) => `/cohorts/${cohortId}/details`,
      path: () => "/cohorts/[cohortId]/details",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    sessions: {
      href: (cohortId: string) => `/cohorts/${cohortId}/sessions`,
      path: () => "/cohorts/[cohortId]/sessions",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
  },
  student: {
    details: {
      href: (studentId: string) => `/students/${studentId}/details`,
      path: () => "/students/[studentId]/details",
      adminModeAccess: [Managing],
      accountRoleAccess: [Admin],
    } as Route,
    cohorts: {
      href: (studentId: string) => `/students/${studentId}/cohorts`,
      path: () => "/students/[studentId]/cohorts",
      adminModeAccess: [Managing],
      accountRoleAccess: [Admin],
    } as Route,
  },
  user: {
    details: {
      href: (userId: string) => `/users/${userId}/details`,
      path: () => "/users/[userId]/details",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    accounts: {
      href: (userId: string) => `/users/${userId}/accounts`,
      path: () => "/users/[userId]/accounts",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    engagements: {
      href: (userId: string) => `/users/${userId}/engagements`,
      path: () => "/users/[userId]/engagements",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    cohorts: {
      href: (userId: string) => `/users/${userId}/cohorts`,
      path: () => "/users/[userId]/cohorts",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    payments: {
      href: (userId: string) => `/users/${userId}/payments`,
      path: () => "/users/[userId]/payments",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    attendance: {
      href: (userId: string) => `/users/${userId}/attendance`,
      path: () => "/users/[userId]/attendance",
      adminModeAccess: [Managing],
      accountRoleAccess: [Admin],
    } as Route,
    schedule: {
      href: (userId: string) => `/users/${userId}/schedule`,
      path: () => "/users/[userId]/schedule",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    pdCourses: {
      href: (userId: string) => `/users/${userId}/pd-courses`,
      path: () => "/users/[userId]/pd-courses",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
    infractions: {
      href: (userId: string) => `/users/${userId}/infractions`,
      path: () => "/users/[userId]/infractions",
      adminModeAccess: [Managing, Staffing],
      accountRoleAccess: [Admin],
    } as Route,
  },
};

const formatParams = (obj?: Record<string, string>) =>
  obj
    ? Object.entries(obj)
        .map((entry) => `${entry[0]}=${entry[1]}`)
        .join("&")
    : {};
