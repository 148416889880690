import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditUserPersonalInfoModalBodyMutation,
  EditUserPersonalInfoModalBodyMutationVariables,
  EditUserPersonalInfoModalBody_UserFragment,
} from "@generated/graphql";
import { Button, ErrorBox, Input, Modal } from "components/shared";
import {
  CountrySelectMenu,
  EthnicitySelectMenu,
  NAStateSelectMenu,
  PronounsSelectMenu,
  RacialIdentitySelectMenu,
  TimeZoneSelectMenu,
} from "components/shared/Inputs/SelectMenuOptions";
import { useState } from "react";

EditUserPersonalInfoModalBody.fragments = {
  user: gql`
    fragment EditUserPersonalInfoModalBody_User on User {
      id
      email
      stateId
      pronouns
      fullName
      timeZone
      countryId
      ethnicity
      phoneNumber
      racialIdentity
    }
  `,
};

const EDIT_USER_PERSONAL_INFO_MUTATION = gql`
  mutation EditUserPersonalInfoModalBody($input: EditUserPersonalInfoInput!) {
    editUserPersonalInfo(input: $input) {
      ...EditUserPersonalInfoModalBody_User
    }
  }
  ${EditUserPersonalInfoModalBody.fragments.user}
`;

type Props = {
  user: EditUserPersonalInfoModalBody_UserFragment;
  closeModal: () => void;
};

export function EditUserPersonalInfoModalBody({ user, closeModal }: Props) {
  const [stateId, setStateId] = useState(user.stateId);
  const [timeZone, setTimeZone] = useState(user.timeZone);
  const [pronouns, setPronouns] = useState(user.pronouns);
  const [countryId, setCountryId] = useState(user.countryId);
  const [ethnicity, setEthnicity] = useState(user.ethnicity);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [racialIdentity, setRacialIdentity] = useState(user.racialIdentity);

  const [editUserPersonalInfo, { loading }] = useMutation<
    EditUserPersonalInfoModalBodyMutation,
    EditUserPersonalInfoModalBodyMutationVariables
  >(EDIT_USER_PERSONAL_INFO_MUTATION, {
    onCompleted: closeModal,
    onError: (err: ApolloError) => setErrorMsg(err.message),
  });

  const onEditUserPersonalInfo = async () =>
    await editUserPersonalInfo({
      variables: {
        input: {
          id: user.id,
          stateId,
          timeZone,
          pronouns,
          countryId,
          ethnicity,
          phoneNumber,
          racialIdentity,
        },
      },
    });

  const handleCountrySelect = (countryId: string | null) => {
    setCountryId(countryId);
    setStateId(null);
    setTimeZone(null);
  };

  const notUsaOrCanada = countryId !== "US" && countryId !== "CA";
  const countryCode = notUsaOrCanada ? "OTHER" : countryId;

  return (
    <div className="flex flex-col p-3 gap-y-4">
      <div className="grid grid-cols-6 gap-4">
        <Input
          disabled
          label="Full Name"
          id="user-full-name"
          className="col-span-6 md:col-span-3"
          value={user.fullName}
        />

        <PronounsSelectMenu
          className="col-span-6 md:col-span-3"
          initialPronouns={user.pronouns}
          onSelect={(pronouns) => setPronouns(pronouns)}
        />

        <Input
          disabled
          id="email"
          label="Email"
          value={user.email}
          className="col-span-6 md:col-span-3"
        />

        <Input
          label="Phone Number"
          id="user-phone-number"
          className="col-span-6 md:col-span-3"
          value={phoneNumber ?? ""}
          placeholder="555-555-5555"
          onChange={(e) => setPhoneNumber(e.target.value)}
        />

        <EthnicitySelectMenu
          className="col-span-6 md:col-span-3"
          initialEthnicity={user.ethnicity}
          onSelect={(ethnicity) => setEthnicity(ethnicity)}
        />

        <RacialIdentitySelectMenu
          className="col-span-6 md:col-span-3"
          initialRacialIdentity={user.racialIdentity}
          onSelect={(identity) => setRacialIdentity(identity)}
        />

        <CountrySelectMenu
          className="col-span-6 md:col-span-2"
          initialValue={countryId}
          selectMenuProps={{
            labelText: "Country of Residence",
          }}
          onSelect={(countryId) => handleCountrySelect(countryId)}
        />

        <NAStateSelectMenu
          initialValue={stateId}
          className="col-span-6 md:col-span-2"
          countryCode={countryCode}
          selectMenuProps={{
            labelText: "State",
            disabled: countryCode === "OTHER",
          }}
          onSelect={(stateId) => setStateId(stateId)}
        />

        <TimeZoneSelectMenu
          countryCode={countryId}
          initialValue={timeZone}
          className="col-span-6 md:col-span-2"
          selectMenuProps={{
            labelText: "Time Zone",
            disabled: countryId === null,
          }}
          onSelect={(timeZone) => setTimeZone(timeZone)}
        />

        <ErrorBox msg={errorMsg} />
      </div>

      <Modal.Buttons>
        <Button
          type="submit"
          loading={loading}
          onClick={onEditUserPersonalInfo}
        >
          Save
        </Button>
        <Modal.Button type="cancel" onClick={closeModal}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </div>
  );
}
