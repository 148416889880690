import clsx from "clsx";
import { useId } from "react";
import { Icon } from "../Icon";
import { NoteStatusIndicator } from "../NoteStatusIndicator";
import { Tooltip } from "../Tooltip";
import { TextArea } from "./TextArea";

type Props = {
  rows?: number;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  value?: string | null;
  mutationLoading: boolean;
  debouncePending: boolean;
  textAreaClassName?: string;
  onChange: (note: string | null) => void;
};

export const DynamicTextArea = ({
  value,
  rows = 1,
  disabled,
  className,
  placeholder,
  debouncePending,
  mutationLoading,
  readOnly = false,
  textAreaClassName,
  onChange,
}: Props) => {
  const id = useId();

  return (
    <div className={clsx("relative", className)}>
      <label htmlFor={id} className="sr-only">
        Add a note
      </label>
      <TextArea
        id={id}
        name="note"
        rows={rows}
        readOnly={readOnly}
        value={value ?? ""}
        disabled={disabled}
        placeholder={placeholder}
        textAreaClassName={textAreaClassName}
        onChange={(event) => onChange(event.target.value)}
        className={clsx("sm:text-sm z-0")}
      />
      {!readOnly && (
        <div className="absolute bottom-[5px] right-[7px] z-[200]">
          {disabled ? (
            <Tooltip content="This note is read-only.">
              <Icon icon="lock" size={4} color="text-slate-700" />
            </Tooltip>
          ) : (
            <NoteStatusIndicator
              debouncePending={debouncePending}
              mutationLoading={mutationLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};
