import clsx from "clsx";
import { useId } from "react";

type Props = {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  labelSide?: "left" | "right";
  onChange?: () => void;
};

export const Checkbox = ({
  label,
  checked,
  disabled,
  className,
  labelClassName,
  labelSide = "left",
  inputClassName = "text-blue-500 focus:ring-blue-500",
  onChange,
}: Props) => {
  const id = useId();
  const checkboxLabel = label && (
    <label
      htmlFor={id}
      className={clsx(
        "block text-md font-medium text-gray-700 cursor-pointer",
        labelClassName
      )}
    >
      {label}
    </label>
  );

  return (
    <div
      className={clsx(
        "flex flex-row gap-2 items-center cursor-pointer",
        className
      )}
    >
      {labelSide === "left" && checkboxLabel}
      <input
        className={clsx(
          "flex w-4 h-4 rounded border-gray-600",
          disabled
            ? "bg-gray-100 cursor-not-allowed"
            : "bg-white cursor-pointer",
          inputClassName
        )}
        id={id}
        disabled={disabled}
        type="checkbox"
        title={id}
        checked={checked}
        onChange={() => onChange?.()}
      />
      {labelSide === "right" && checkboxLabel}
    </div>
  );
};
