import clsx from "clsx";
import { Icon } from "../Icon";

export const getSubHeaderPillItem = (
  name: string,
  color: string,
  transparent = false
) => (
  <div
    key={`${name}-${color}`}
    className={clsx(
      "flex flex-center text-center !leading-3 flex-nowrap",
      "!text-xs !tracking-normal !px-2 !py-[3px] !font-semibold rounded-full !normal-case",
      transparent && "opacity-0",
      color
    )}
  >
    {name}
  </div>
);

export const renderIndicatorCell = (
  name: string,
  color: string,
  value: number,
  className?: string
) => (
  <div className="relative" key={name}>
    {getSubHeaderPillItem(name, color, true)}
    <div
      className={clsx(
        "flex absolute top-0 left-0 w-full h-full flex-center",
        className
      )}
    >
      <div
        className={clsx(
          "flex flex-center leading-tight w-fit min-w-[26px]",
          "px-[6px] py-[3px] rounded-full text-sm font-semibold",
          color
        )}
      >
        {value}
      </div>
    </div>
  </div>
);

export const renderTooltip = (tip: string) => (
  <div className="!normal-case !text-sm !tracking-normal !font-semibold">
    {tip}
  </div>
);

export const renderSortIndicator = (
  isSorted: boolean,
  isSortedDesc = false,
  left: number,
  padding: string
) =>
  isSorted ? (
    <div
      style={{ left: left === 0 ? "0" : `${left - 10}px` }}
      className={clsx(
        "absolute flex h-full pointer-events-none",
        isSortedDesc ? "top-[calc(100%-10px)]" : "top-[3px]",
        left === 0 && "w-full justify-center",
        padding,
        "!py-0"
      )}
    >
      <div className="flex h-[7px] w-[20px] items-center justify-center rounded-full bg-blue-600">
        <Icon
          icon={isSortedDesc ? "sortDown" : "sortUp"}
          color="text-white h-[10px] w-[10px]"
        />
      </div>
    </div>
  ) : null;
