import { Dialog, Transition } from "@headlessui/react";
import { getScrollbarStyle } from "@utils/styleStrings";
import { assertUnreachable } from "@utils/types";
import clsx from "clsx";
import { useLayout } from "contexts/LayoutProvider";
import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { Button, ThemeT } from "./Buttons/Button";
import { Icon, IconType } from "./Icon";

export type WidthOptions =
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge"
  | "xxxlarge"
  | "xxxxlarge"
  | "full";

type Props = {
  show: boolean;
  dataTest?: string;
  width?: WidthOptions;
  icon?: React.ReactNode;
  children: React.ReactNode;
  modalIsTopSticky?: boolean;
  actionButton?: React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
  afterLeave?: () => void;
  onClose: () => void; // Provide noop to prevent closing on outside click.
  onDismissClick?: () => void; // Adds X button to corner when defined.
};

export const Modal = ({
  show,
  icon,
  title,
  children,
  subtitle,
  dataTest,
  initialFocus,
  actionButton,
  width = "medium",
  modalIsTopSticky = false,
  onClose,
  afterLeave,
  onDismissClick,
}: Props) => {
  const { screenHeight: windowH } = useLayout();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const focusRef = initialFocus ? null : modalRef;

  const modalHeightRef = useRef<HTMLDivElement | null>(null);
  const initModalH = modalHeightRef.current?.clientHeight ?? 0.91 * 700;
  const [modalHeight, setModalHeight] = useState<number>(initModalH);
  const [screenHeight, setScreenHeight] = useState<number>(windowH ?? 700);
  const [debouncedModalHeight] = useDebounce(show, 100);

  useEffect(() => {
    if (debouncedModalHeight && show && modalHeightRef.current) {
      setModalHeight(initModalH);
      setScreenHeight(windowH ?? 700);
    }
  }, [debouncedModalHeight, initModalH, show, windowH]);

  const getModalMarginTop = () => {
    if (modalIsTopSticky || modalHeight > 0.9 * screenHeight) return 54;
    return Math.max(30, screenHeight / 3 - modalHeight / 2);
  };

  return (
    <Transition.Root show={show} as={React.Fragment}>
      <Dialog
        as="div"
        className={clsx(
          "fixed z-10 inset-0 overflow-y-auto",
          getScrollbarStyle("dark")
        )}
        initialFocus={initialFocus}
        onClose={onClose}
      >
        {/* Layer covers entire screen and centers content */}
        <div
          ref={focusRef}
          className="flex min-w-screen justify-center items-start relative min-h-screen"
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This is the actual modal content */}
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            afterLeave={afterLeave}
          >
            <div
              className={clsx(
                getModalWidth(width),
                "border relative",
                "p-4 mb-12 sm:p-5",
                "bg-white rounded-lg shadow-xl",
                "transform transition-all h-fit"
              )}
              ref={modalHeightRef}
              style={{ marginTop: `${getModalMarginTop()}px` }}
              data-test={dataTest}
            >
              {(onDismissClick || actionButton) && (
                <div
                  className={clsx(
                    "flex absolute top-4 z-[200] right-4 text-gray-700 hover:text-red-600 cursor-pointer",
                    onDismissClick && "flex-center"
                  )}
                >
                  {actionButton && actionButton}
                  {onDismissClick && (
                    <div className="group" onClick={onDismissClick}>
                      <Icon
                        icon="remove"
                        size={8}
                        color="text-slate-500 group-hover:text-rose-800"
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="relative sm:flex w-full">
                {icon && (
                  <div className="flex justify-center align-center w-[40px]">
                    {icon}
                  </div>
                )}
                {title && (
                  <div
                    className={clsx(
                      "relative flex flex-col mt- w-full text-left",
                      subtitle ? "mt-1" : "mt-2",
                      Boolean(icon) && "sm:ml-4 text-center sm:text-left"
                    )}
                  >
                    <Dialog.Title
                      as="h3"
                      className="text-gray-700 text-xl font-bold leading-6"
                    >
                      {title}
                    </Dialog.Title>
                    {subtitle && (
                      <div className="relative text-center sm:mt-0 sm:text-left sm:w-[calc(100%-150px)] w-full">
                        <Dialog.Title
                          as="h4"
                          className="text-gray-900 text-sm font-small leading-tight"
                        >
                          {subtitle}
                        </Dialog.Title>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="mt-2 relative z-5">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const getModalWidth = (options: WidthOptions) => {
  switch (options) {
    case "small":
      return "w-full sm:max-w-lg";
    case "medium":
      return "w-full sm:max-w-xl";
    case "large":
      return "w-full sm:max-w-2xl";
    case "xlarge":
      return "w-full sm:max-w-3xl";
    case "xxlarge":
      return "w-full sm:max-w-4xl";
    case "xxxlarge":
      return "w-full sm:max-w-5xl";
    case "xxxxlarge":
      return "w-full sm:max-w-7xl";
    case "full":
      return "w-full sm:max-w-[90vw]";
    default:
      return "w-full sm:max-w-lg";
  }
};

const ModalButtons = ({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}) => (
  <div
    className={clsx(
      "mt-3 mr-px flex flex-col sm:flex-row-reverse gap-3",
      className
    )}
  >
    {children}
  </div>
);

type ModalButtonType = "confirm" | "cancel" | "delete";

const getButtonStyles = (
  type: ModalButtonType
): {
  theme: ThemeT;
} => {
  switch (type) {
    case "confirm":
      return { theme: "primary" };
    case "cancel":
      return { theme: "tertiary" };
    case "delete":
      return { theme: "danger" };
    default:
      assertUnreachable(type, "ModalButtonType");
  }
};

type ModalButtonProps = {
  type: ModalButtonType;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
};

const ModalButtonForwardRef = (
  {
    type,
    onClick,
    children,
    disabled = false,
    loading = false,
  }: ModalButtonProps,
  ref: React.Ref<HTMLButtonElement>
) => {
  const { theme } = getButtonStyles(type);

  return (
    <Button
      theme={theme}
      className="px-4 w-full sm:min-w-[80px] sm:w-auto"
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      loading={loading}
    >
      {children}
    </Button>
  );
};

ModalButtonForwardRef.displayName = "ModalButton";

const ModalButton = React.forwardRef<HTMLButtonElement, ModalButtonProps>(
  ModalButtonForwardRef
);

export type ModalIconType = "classic" | "danger" | "warning";

const getModalIconsTypeStyles = (type: ModalIconType) => {
  switch (type) {
    case "classic":
      return "bg-blue-500";
    case "danger":
      return "bg-rose-500";
    case "warning":
      return "bg-orange-500";
    default:
      return "bg-blue-500";
  }
};

type ModalIconProps = {
  children?: React.ReactNode;
  className?: string;
  icon?: IconType;
  type?: ModalIconType;
};

const ModalIcon = ({ children, icon, type = "classic" }: ModalIconProps) => (
  <div
    className={clsx(
      "flex flex-shrink-0 flex-center mx-auto w-12 h-12 rounded-full sm:mx-0 sm:w-10 sm:h-10",
      getModalIconsTypeStyles(type)
    )}
  >
    {icon ? <Icon icon={icon} size={6} color="text-white" /> : children}
  </div>
);

Modal.Buttons = ModalButtons;
Modal.Button = ModalButton;
Modal.Icon = ModalIcon;
