import { EducationDegree } from "@generated/graphql";
import { userEducationDegreeOptions } from "@utils/selectMenuOptions";
import { useMemo } from "react";
import { SelectMenu, SelectMenuProps } from "../SelectMenu";

type Props = {
  className?: string;
  initialDegree?: EducationDegree | null;
  selectMenuProps?: Exclude<SelectMenuProps, "initialIndex">;
  onSelect: (degree: EducationDegree | null) => void;
};

export const EducationDegreeSelectMenu = ({
  className,
  initialDegree,
  selectMenuProps,
  onSelect,
}: Props) => {
  const initialIndex = useMemo(() => {
    return userEducationDegreeOptions().findIndex(
      ({ degree }) => degree === initialDegree
    );
  }, [initialDegree]);

  return (
    <SelectMenu
      labelText="Education Degree"
      {...selectMenuProps}
      className={className}
      options={userEducationDegreeOptions()}
      initialIndex={initialIndex}
      onSelect={({ degree }) => onSelect(degree)}
    />
  );
};
