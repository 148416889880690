import { gql, useMutation, useQuery } from "@apollo/client";
import {
  EditTutoringPreferencesModalQuery,
  EditTutoringPreferencesModalQueryVariables,
  TutoringPreferencesCardInitializeMutation,
  TutoringPreferencesCardInitializeMutationVariables,
  User,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { LoadingSkeleton, Modal } from "components/shared";
import { useEffect } from "react";
import { EditTutoringPreferencesModalBody } from "./components/EditTutoringPreferencesModalBody";

const TUTORING_PREFERENCES_CARD_INITIALIZE_MUTATION = gql`
  mutation TutoringPreferencesCardInitialize(
    $input: EditTutoringPreferencesInput!
  ) {
    editTutoringPreferences(input: $input) {
      id
    }
  }
`;

const EDIT_TUTORING_PREFERENCES_MODAL_QUERY = gql`
  query EditTutoringPreferencesModal($userId: ID!) {
    getTutoringPreferencesForUser(userId: $userId) {
      id
      ...EditTutoringPreferencesModalBody_TutoringPreferences
    }
  }
  ${EditTutoringPreferencesModalBody.fragments.tutoringPreferences}
`;

type Props = {
  show: boolean;
  userId: User["id"];
  hasPreferences: boolean;
  refetch?: () => void;
  closeModal: () => void;
};

export function EditTutoringPreferencesModal({
  show,
  userId,
  hasPreferences,
  refetch,
  closeModal,
}: Props) {
  const [initializeTutoringPreferences, { loading: initLoading }] = useMutation<
    TutoringPreferencesCardInitializeMutation,
    TutoringPreferencesCardInitializeMutationVariables
  >(TUTORING_PREFERENCES_CARD_INITIALIZE_MUTATION, {
    variables: { input: { userId } },
    onCompleted: () => refetch?.(),
  });

  const { data, loading } = useQuery<
    EditTutoringPreferencesModalQuery,
    EditTutoringPreferencesModalQueryVariables
  >(EDIT_TUTORING_PREFERENCES_MODAL_QUERY, {
    variables: { userId },
    fetchPolicy: "network-only",
    skip: !show || !hasPreferences,
    onError: (err) => fetchErrToast("tutoring preferences data", err),
  });

  useEffect(() => {
    show && !hasPreferences && initializeTutoringPreferences();
  }, [hasPreferences, initializeTutoringPreferences, show]);

  return (
    <Modal
      show={show}
      width="xlarge"
      title="Edit Tutoring Preferences"
      icon={<Modal.Icon icon="details" />}
      subtitle="Please enter your tutoring preferences to receive aligned teaching opportunities"
      onClose={closeModal}
    >
      {!data?.getTutoringPreferencesForUser || loading || initLoading ? (
        <LoadingSkeleton rows={5} />
      ) : (
        <EditTutoringPreferencesModalBody
          userId={userId}
          tutoringPreferences={data.getTutoringPreferencesForUser}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
}
