import { getScrollbarStyle } from "@utils/styleStrings";
import { clsx } from "clsx";
import { CardDetailsRow, CardSize, HeightMode } from "../types";

export const Label = 0;
export const Value = 1;

type Props = {
  size?: CardSize;
  className?: string;
  heightMode?: HeightMode;
  rows: CardDetailsRow[];
};

export const CardDetails = ({
  heightMode = HeightMode.Fit,
  size = "sm",
  className,
  rows,
}: Props) => {
  const style = getStyle(size);

  return (
    <table
      className={clsx(
        "w-full overflow-x-auto divide-gray-200 divide-y",
        getScrollbarStyle("gray"),
        heightMode,
        className
      )}
    >
      <tbody>
        {rows.map((row, i) => (
          <tr key={i} className="odd:bg-slate-50 even:bg-slate-100">
            <td
              className={clsx(
                "font-semibold text-slate-600 leading-tight flex-wrap pr-3 py-2",
                style.cell,
                style.label
              )}
            >
              {row[Label]}
            </td>
            <td
              className={clsx(
                "font-medium text-slate-500 leading-tight flex-wrap pr-3 py-2",
                style.cell,
                style.value
              )}
            >
              {row[Value]}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const getStyle = (size: "sm" | "md" | "lg") => {
  switch (size) {
    case "sm":
      return {
        cell: "h-[34px]",
        label: "pl-3 text-sm",
        value: "text-sm",
      };
    default:
    case "md":
      return {
        cell: "h-[38px]",
        label: "pl-3 text-md",
        value: "text-md",
      };
    case "lg":
      return {
        cell: "h-[50px]",
        label: "pl-4 text-lg",
        value: "text-lg",
      };
  }
};
