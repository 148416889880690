import clsx from "clsx";
import { ReactNode } from "react";

interface Props {
  disabled?: boolean;
  className?: string;
  name: string | ReactNode;
  onChange?: () => void;
}

export const PillButton = ({
  name,
  className,
  disabled = false,
  onChange,
}: Props) => (
  <button
    key={String(name)}
    type="button"
    disabled={disabled}
    onClick={onChange}
    className={clsx(
      "inline-flex cursor-pointer items-center px-3 py-0.5",
      "rounded-full h-7 text-xs font-medium text-gray-700",
      className
    )}
  >
    {name}
  </button>
);
