import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditTutoringPreferencesModalBodyMutation,
  EditTutoringPreferencesModalBodyMutationVariables,
  EditTutoringPreferencesModalBody_TutoringPreferencesFragment,
  User,
} from "@generated/graphql";
import { getCohortSubjectText } from "@utils/labels";
import clsx from "clsx";
import { Button, ErrorBox, Input, Modal } from "components/shared";
import { PillSelectInput } from "components/shared/Inputs";
import {
  YesNoNullTheme,
  YesOrNoInput,
} from "components/shared/Inputs/YesOrNoInput";
import { find } from "lodash";
import { Fragment, useState } from "react";
import { TimeSlotRangeTooltip } from "sections/Profile/components";
import {
  cohortSubjectOptions,
  enEsComfortableQuestion,
  esComfortableQuestion,
  esFluentQuestion,
  itemContentStyle,
  itemSectionHeaderStyle,
  itemSubHeaderStyle,
  sectionBodyWrapperStyle,
  sectionWrapperStyle,
} from "sections/Profile/constants";
import { EllEldTooltip, TestPrepTooltip } from "sections/Profile/helpers";
import {
  dayOfWeekPillOptions,
  gradePillOptions,
  positionPillOptions,
  testPrepPillOptions,
} from "./constants";

EditTutoringPreferencesModalBody.fragments = {
  tutoringPreferences: gql`
    fragment EditTutoringPreferencesModalBody_TutoringPreferences on TutoringPreferences {
      id
      positions
      subjects {
        id
        subject
        grades
      }
      ellEld
      testPrepSubjects
      esFluent
      enEsComfortable
      esComfortable
      additionalLanguages
      availabilities {
        daysOfWeek
        timeSlotId
        startTime
        endTime
        timeZone
      }
    }
  `,
};

const EDIT_TUTORING_PREFERENCES_MUTATION = gql`
  mutation EditTutoringPreferencesModalBody(
    $input: EditTutoringPreferencesInput!
  ) {
    editTutoringPreferences(input: $input) {
      id
      ...EditTutoringPreferencesModalBody_TutoringPreferences
    }
  }
  ${EditTutoringPreferencesModalBody.fragments.tutoringPreferences}
`;

type Props = {
  userId: User["id"];
  tutoringPreferences: EditTutoringPreferencesModalBody_TutoringPreferencesFragment;
  closeModal: () => void;
};

export function EditTutoringPreferencesModalBody({
  userId,
  tutoringPreferences: tp,
  closeModal,
}: Props) {
  const { MiniMuted } = YesNoNullTheme;
  const [ellEld, setEllEld] = useState(tp.ellEld);
  const [subjects, setSubjects] = useState(tp.subjects);
  const [esFluent, setEsFluent] = useState(tp.esFluent);
  const selectedSubjects = subjects.map((s) => s.subject);
  const [positions, setPositions] = useState(tp.positions);
  const hasTestPrepSubjects = tp.testPrepSubjects.length > 0;
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [esComfortable, setEsComfortable] = useState(tp.esComfortable);
  const [availabilities, setAvailabilities] = useState(tp.availabilities);
  const [enEsComfortable, setEnEsComfortable] = useState(tp.enEsComfortable);
  const [hasPrepSubjects, setHasPrepSubjects] = useState(hasTestPrepSubjects);
  const [testPrepSubjects, setTestPrepSubjects] = useState(tp.testPrepSubjects);

  const [additionalLanguages, setAdditionalLanguages] = useState(
    tp.additionalLanguages
  );

  const [editTutoringPreferences, { loading }] = useMutation<
    EditTutoringPreferencesModalBodyMutation,
    EditTutoringPreferencesModalBodyMutationVariables
  >(EDIT_TUTORING_PREFERENCES_MUTATION, {
    onCompleted: closeModal,
    onError: (err: ApolloError) => setErrorMsg(err.message),
  });

  const onEditTutoringPreferences = async () =>
    await editTutoringPreferences({
      variables: {
        input: {
          userId,
          ellEld,
          esFluent,
          positions,
          esComfortable,
          enEsComfortable,
          testPrepSubjects,
          additionalLanguages,
          subjects: subjects
            .filter(({ grades }) => grades.length > 0)
            .map(({ subject, grades }) => ({
              subject,
              grades,
            })),
          availabilities: availabilities
            .filter(({ daysOfWeek }) => !!daysOfWeek?.length)
            .map(({ timeSlotId, daysOfWeek }) => ({
              timeSlotId,
              daysOfWeek,
            })),
        },
      },
    });

  return (
    <div className="flex flex-col gap-y-4 mt-7 px-3">
      {/* Teaching Positions */}
      <div className={sectionWrapperStyle}>
        <span className={itemSectionHeaderStyle}>Teaching Positions</span>
        <div className={sectionBodyWrapperStyle}>
          <PillSelectInput
            selected={positions}
            options={positionPillOptions}
            onChange={(selected) => setPositions(selected)}
          />
        </div>
      </div>

      {/* Availability */}
      <div className={sectionWrapperStyle}>
        <span className={itemSectionHeaderStyle}>Availability</span>
        <div
          className={clsx(
            "grid grid-cols-[auto_1fr] gap-x-[70px] items-center gap-y-3",
            sectionBodyWrapperStyle
          )}
        >
          {availabilities.map((availability, i) => (
            <Fragment key={i}>
              <TimeSlotRangeTooltip
                className={clsx(
                  "min-w-[90px]",
                  itemSubHeaderStyle,
                  (availability.daysOfWeek ?? []).length > 0 &&
                    "!text-blue-800/90"
                )}
                tutoringPreferencesAvailability={availability}
              />
              <PillSelectInput
                hideIcons
                options={dayOfWeekPillOptions}
                selected={availability.daysOfWeek ?? []}
                onChange={(daysOfWeek) =>
                  setAvailabilities(
                    availabilities.map((a) =>
                      a.timeSlotId === availability.timeSlotId
                        ? { ...a, daysOfWeek }
                        : a
                    )
                  )
                }
              />
            </Fragment>
          ))}
        </div>
      </div>

      {/* Test Prep */}
      <div className={sectionWrapperStyle}>
        <TestPrepTooltip />
        <div className={clsx(sectionBodyWrapperStyle, "gap-[80px]")}>
          <YesOrNoInput
            theme={MiniMuted}
            isYes={hasPrepSubjects}
            onChange={(value) => {
              setHasPrepSubjects(!!value);
              if (!value) setTestPrepSubjects([]);
            }}
          />

          {hasPrepSubjects && (
            <PillSelectInput
              className="-my-2"
              selected={testPrepSubjects}
              options={testPrepPillOptions}
              onChange={(selected) => setTestPrepSubjects(selected)}
            />
          )}
        </div>
      </div>

      {/* Subjects & Grades */}
      <div className={sectionWrapperStyle}>
        <span className={itemSectionHeaderStyle}>Subjects & Grades</span>
        <div
          className={clsx(
            "grid grid-cols-[auto_1fr] gap-y-3 gap-x-[41px]",
            sectionBodyWrapperStyle
          )}
        >
          {cohortSubjectOptions.map((subject) => {
            const content = getCohortSubjectText(subject);
            const subjectDetails = find(subjects, { subject });
            const subjectIsSelected = !!subjectDetails;

            return (
              <Fragment key={subject}>
                <PillSelectInput
                  selected={selectedSubjects}
                  options={[{ value: subject, content }]}
                  onChange={() =>
                    setSubjects(
                      subjectIsSelected
                        ? subjects.filter((s) => s.subject !== subject)
                        : [...subjects, { id: "", subject, grades: [] }]
                    )
                  }
                />
                {subjectIsSelected ? (
                  <PillSelectInput
                    hideIcons
                    selected={subjectDetails?.grades ?? []}
                    options={gradePillOptions}
                    onChange={(grades) =>
                      setSubjects(
                        subjects.map((s) =>
                          s.subject === subject ? { ...s, subject, grades } : s
                        )
                      )
                    }
                  />
                ) : (
                  <div className={clsx(itemContentStyle, "ml-[6px]")}>--</div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>

      {/* ELL/ELD */}
      <div className={sectionWrapperStyle}>
        <EllEldTooltip />
        <div className={clsx(sectionBodyWrapperStyle, "gap-6")}>
          <YesOrNoInput
            isYes={ellEld}
            theme={MiniMuted}
            onChange={(isYes) => setEllEld(!!isYes)}
          />
        </div>
      </div>

      {/* Languages Spoken */}
      <div className={sectionWrapperStyle}>
        <span className={itemSectionHeaderStyle}>Languages Spoken</span>
        <div className="flex flex-col gap-y-2 p-3 border rounded-lg bg-slate-50/70">
          <div className="flex items-center gap-x-5">
            <span className={itemContentStyle}>{esFluentQuestion}</span>
            <YesOrNoInput
              isYes={esFluent}
              theme={MiniMuted}
              onChange={(value) => setEsFluent(!!value)}
            />
          </div>
          <div className="flex items-center gap-x-5">
            <span className={itemContentStyle}>{esComfortableQuestion}</span>
            <YesOrNoInput
              isYes={esComfortable}
              theme={MiniMuted}
              onChange={(value) => setEsComfortable(!!value)}
            />
          </div>
          <div className="flex items-center gap-x-5">
            <span className={itemContentStyle}>{enEsComfortableQuestion}</span>
            <YesOrNoInput
              isYes={enEsComfortable}
              theme={MiniMuted}
              onChange={(value) => setEnEsComfortable(!!value)}
            />
          </div>
          <div className="flex flex-col gap-x-5 w-full gap-y-1">
            <span className={itemContentStyle}>Additional Languages?</span>

            <Input
              id="additionalLanguages"
              value={additionalLanguages ?? ""}
              onChange={(e) => setAdditionalLanguages(e.target.value)}
            />
          </div>
        </div>
      </div>

      <ErrorBox msg={errorMsg} />

      <Modal.Buttons className="!mt-0">
        <Button
          type="submit"
          loading={loading}
          onClick={onEditTutoringPreferences}
        >
          Save
        </Button>
        <Modal.Button type="cancel" onClick={closeModal}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </div>
  );
}
