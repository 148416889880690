import { gql, useQuery } from "@apollo/client";
import { FinishTaxFormsChecklistItemQuery } from "@generated/graphql";
import { ChecklistItem, Icon, Link } from "components/shared";
import { useEffect, useState } from "react";
import {
  checklistLinkStyle,
  itemContentStyle,
} from "sections/Profile/constants";

const GET_ARE_TAXES_SETUP_CHECK = gql`
  query FinishTaxFormsChecklistItem($userId: ID!) {
    areTaxesSetup(userId: $userId)
  }
`;

type Props = {
  setError: (msg: string) => void;
  userId: string;
};

export const FinishTaxFormsChecklistItem = ({ setError, userId }: Props) => {
  const [taxesAreSetup, setTaxesAreSetup] = useState(false);

  const { data, loading } = useQuery<FinishTaxFormsChecklistItemQuery>(
    GET_ARE_TAXES_SETUP_CHECK,
    {
      variables: { userId },
      fetchPolicy: "network-only",
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  useEffect(() => {
    if (data) return setTaxesAreSetup(data.areTaxesSetup);
  }, [data]);

  return (
    <ChecklistItem
      loading={loading}
      trackedForCompletion
      complete={taxesAreSetup}
      collapsibleContent={
        taxesAreSetup ? null : (
          <div className={itemContentStyle}>
            This is the final step of setting up your payment account!
          </div>
        )
      }
      title={
        <div className="flex items-center gap-x-1">
          Submit your tax form (W9)
          <Link
            className={checklistLinkStyle}
            href="https://suppliers.tipalti.com/TutoredbyTeachers/Account/Login"
            target="__blank"
          >
            Visit Tipalti
            <Icon
              size={4}
              icon="externalLink"
              color="text-blue-800 group-hover:text-blue-600"
            />
          </Link>
        </div>
      }
    />
  );
};
