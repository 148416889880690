import clsx from "clsx";
import { Icon } from "../Icon";
import { Spinner } from "../Loading/Spinner";

type Props = {
  dataName?: string;
  loading?: boolean;
  emptyIcon?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  description?: string;
  className?: string;
};

export const EmptyTableState = ({
  loading,
  dataName,
  title,
  subtitle,
  emptyIcon,
  className,
  description,
}: Props) => (
  <div
    className={clsx(
      "flex flex-col flex-center gap-y-1",
      "text-md w-full border-t border-gray-200 rounded-b-lg h-full bg-gray-50/70 p-5",
      className
    )}
  >
    {emptyIcon ?? <Icon icon="database" size={8} />}

    <h2 className="text-lg font-medium text-gray-700 leading-tight">
      {title
        ? loading
          ? "Loading"
          : title
        : `${loading ? "Loading" : "No"} ${dataName ?? "Data"}`}
    </h2>

    {loading ? (
      <Spinner size={5} className="mt-2" color="text-blue-400" />
    ) : (
      description ?? (
        <p className="text-sm text-gray-500">
          {subtitle ??
            `Add ${dataName ?? "Data"} or refine your search to see results.`}
        </p>
      )
    )}
  </div>
);
