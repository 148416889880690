import { TeachingExperienceCard_TeachingExperienceFragment } from "@generated/graphql";
import { Modal } from "components/shared";
import { AddEditTeachingExperienceModalBody } from "./AddEditTeachingExperienceModalBody";

type Props = {
  show: boolean;
  userId: string;
  experience?: TeachingExperienceCard_TeachingExperienceFragment | null;
  closeModal: () => void;
};

export const AddEditTeachingExperienceModal = ({
  show,
  userId,
  experience,
  closeModal,
}: Props) => (
  <Modal
    show={show}
    width="medium"
    title="Teaching Experience"
    icon={<Modal.Icon icon="requirements" />}
    subtitle="Let us know your teaching experience."
    onClose={closeModal}
  >
    <AddEditTeachingExperienceModalBody
      userId={userId}
      experience={experience}
      closeModal={closeModal}
    />
  </Modal>
);
