import { gql, useQuery } from "@apollo/client";
import {
  GetTbtBasicsCourseStatusQuery,
  TalentLmsCourseProgressStatus,
} from "@generated/graphql";
import { ChecklistItem, Icon, Link } from "components/shared";
import { useEffect, useState } from "react";
import {
  checklistLinkStyle,
  itemContentStyle,
} from "sections/Profile/constants";

const GET_TBT_BASICS_COURSE_STATUS = gql`
  query GetTbtBasicsCourseStatus($userId: ID!) {
    getTbtBasicsCourseStatus(userId: $userId) {
      status
      goToUrl
    }
  }
`;

type Props = {
  userId: string;
  setError: (msg: string) => void;
};

export const TbtBasicsChecklistItem = ({ userId, setError }: Props) => {
  const [courseIsComplete, setCourseIsComplete] = useState<boolean>(false);

  const { data, loading } = useQuery<GetTbtBasicsCourseStatusQuery>(
    GET_TBT_BASICS_COURSE_STATUS,
    {
      variables: { userId },
      fetchPolicy: "network-only",
      onError: (error) => setError(error.message),
    }
  );

  useEffect(() => {
    if (data)
      return setCourseIsComplete(
        data.getTbtBasicsCourseStatus?.status ===
          TalentLmsCourseProgressStatus.Completed
      );
  }, [data]);

  return (
    <ChecklistItem
      loading={loading}
      complete={courseIsComplete}
      trackedForCompletion
      collapsibleContent={
        courseIsComplete ? null : (
          <div className={itemContentStyle}>
            Look for an email invitation from noreply@talentlms.com!
          </div>
        )
      }
      title={
        <div className="flex items-center gap-x-1">
          Complete TbT Basics
          <Link
            className={checklistLinkStyle}
            href={data?.getTbtBasicsCourseStatus?.goToUrl ?? ""}
            target="__blank"
          >
            View Course Status
            <Icon
              size={4}
              icon="externalLink"
              color="text-blue-800 group-hover:text-blue-600"
            />
          </Link>
        </div>
      }
    />
  );
};
