import { gql, useQuery } from "@apollo/client";
import {
  EditUserPersonalInfoModalQuery,
  EditUserPersonalInfoModalQueryVariables,
  User,
} from "@generated/graphql";
import { fetchErrToast } from "@utils/errorLogging";
import { LoadingSkeleton, Modal } from "components/shared";
import { EditUserPersonalInfoModalBody } from "./components/EditUserPersonalInfoModalBody";

const EDIT_USER_PROFILE_MODAL_QUERY = gql`
  query EditUserPersonalInfoModal($id: ID!) {
    user(id: $id) {
      id
      ...EditUserPersonalInfoModalBody_User
    }
  }
  ${EditUserPersonalInfoModalBody.fragments.user}
`;

type Props = {
  show: boolean;
  userId: User["id"];
  closeModal: () => void;
};

export const EditUserPersonalInfoModal = ({
  show,
  userId,
  closeModal,
}: Props) => {
  const { data, loading } = useQuery<
    EditUserPersonalInfoModalQuery,
    EditUserPersonalInfoModalQueryVariables
  >(EDIT_USER_PROFILE_MODAL_QUERY, {
    skip: !show,
    variables: { id: userId },
    fetchPolicy: "network-only",
    onError: (error) => fetchErrToast("your profile details", error),
  });

  return (
    <Modal
      show={show}
      width="xxlarge"
      title="Edit Personal Information"
      icon={<Modal.Icon icon="profile" />}
      subtitle="Please fill out these fields so that we can best customize your experience."
      onClose={closeModal}
    >
      {loading || !data?.user ? (
        <LoadingSkeleton />
      ) : (
        <EditUserPersonalInfoModalBody
          user={data.user}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};
