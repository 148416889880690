import { useLazyQuery } from "@apollo/client";
import { MINUTE_MS } from "@utils/dateTime";
import { onError } from "@utils/errorLogging";
import { useAuth } from "contexts/AuthProvider";
import { gql } from "graphql-tag";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const REFETCH = MINUTE_MS;

const TEACHER_SIDEBAR_DATA = gql`
  query TeacherSidebarData {
    getTeacherSidebarData {
      newJobsCount
      jobOffersCount
    }
  }
`;

const ADMIN_SIDEBAR_DATA = gql`
  query AdminSidebarData {
    getAdminSidebarData {
      liveCohortsCount
      liveEngagementsCount
    }
  }
`;

const initialState = {
  // TEACHER SIDEBAR DATA
  newJobsCount: 0,
  jobOffersCount: 0,

  // ADMIN SIDEBAR DATA
  liveCohortsCount: 0,
  liveEngagementsCount: 0,

  // FETCH && REFETCH LOGIC
  sidebarLoading: false,
  fetchSidebarData: () => {},
};

interface SidebarDataContextType {
  // TEACHER SIDEBAR DATA
  newJobsCount: number;
  jobOffersCount: number;

  // ADMIN SIDEBAR DATA
  liveCohortsCount: number;
  liveEngagementsCount: number;

  // FETCH && REFETCH LOGIC
  sidebarLoading: boolean;
  fetchSidebarData: () => void;
}

const SidebarDataContext = createContext<SidebarDataContextType>(initialState);

export const SidebarDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, isAdmin, isTeacher, isAuthenticating } = useAuth();

  // TEACHER SIDEBAR DATA
  const [newJobsCount, setNewJobsCount] = useState<number>(0);
  const [jobOffersCount, setJobOffersCount] = useState<number>(0);

  const [fetchTeacherSidebarData, { loading: teacherLoading }] = useLazyQuery(
    TEACHER_SIDEBAR_DATA,
    { fetchPolicy: "no-cache" }
  );

  const fetchTeacherData = useCallback(() => {
    fetchTeacherSidebarData({
      onError,
      onCompleted: ({ getTeacherSidebarData: data }) => {
        setNewJobsCount(data.newJobsCount);
        setJobOffersCount(data.jobOffersCount);
      },
    });
  }, [fetchTeacherSidebarData]);

  // ADMIN SIDEBAR DATA
  const [liveCohortsCount, setLiveCohortsCount] = useState<number>(0);
  const [liveEngagementsCount, setLiveEngagementsCount] = useState<number>(0);

  const [fetchAdminSidebarData, { loading: adminLoading }] = useLazyQuery(
    ADMIN_SIDEBAR_DATA,
    { fetchPolicy: "no-cache" }
  );

  const fetchAdminData = useCallback(() => {
    fetchAdminSidebarData({
      onError,
      onCompleted: ({ getAdminSidebarData: data }) => {
        setLiveCohortsCount(data.liveCohortsCount);
        setLiveEngagementsCount(data.liveEngagementsCount);
      },
    });
  }, [fetchAdminSidebarData]);

  // FETCH && REFETCH LOGIC
  const fetchSidebarData = useCallback(() => {
    if (!!user?.id && !isAuthenticating) {
      if (isAdmin) fetchAdminData();
      if (isTeacher) fetchTeacherData();
    }
  }, [
    isAdmin,
    user?.id,
    isTeacher,
    isAuthenticating,
    fetchAdminData,
    fetchTeacherData,
  ]);

  useEffect(() => {
    const intervalId = setInterval(fetchSidebarData, REFETCH);
    return () => clearInterval(intervalId);
  }, [fetchSidebarData]);

  const contextValue = {
    // TEACHER SIDEBAR DATA
    newJobsCount,
    jobOffersCount,

    // ADMIN SIDEBAR DATA
    liveCohortsCount,
    liveEngagementsCount,

    // FETCH && REFETCH LOGIC
    sidebarLoading: teacherLoading || adminLoading,
    fetchSidebarData,
  };

  return (
    <SidebarDataContext.Provider value={contextValue}>
      {children}
    </SidebarDataContext.Provider>
  );
};

export const useSidebarData = () => useContext(SidebarDataContext);
