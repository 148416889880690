import { gql } from "@apollo/client";
import { UserDetailsCard_UserFragment } from "@generated/graphql";
import {
  getCountryString,
  getNAStateString,
  getTimeZoneString,
} from "@utils/geo";
import {
  getUserEthnicityText,
  getUserPronounsText,
  getUserRacialIdentityText,
} from "@utils/labels";
import {
  AccountRoleBadge,
  AccountStatusBadge,
  AutoPayBadge,
} from "components/shared";
import {
  Card,
  CardDetailsRow,
  getEditModalOpenHeader,
  HeaderColors,
  HeightMode,
} from "components/shared/Card";
import { getNormalizedShortReadableDate } from "helpers/dateText";
import { useState } from "react";
import { EditUserPersonalInfoModal } from "sections/Profile/components";

UserDetailsCard.fragments = {
  user: gql`
    fragment UserDetailsCard_User on User {
      id
      email
      stateId
      timeZone
      fullName
      countryId
      pronouns
      ethnicity
      createdAt
      phoneNumber
      accountRole
      inviteSentAt
      accountStatus
      racialIdentity
    }
  `,
};

type Props = {
  className?: string;
  showAutoPay?: boolean;
  forAdminView?: boolean;
  autoPayEnabled?: boolean;
  user: UserDetailsCard_UserFragment;
};

export function UserDetailsCard({
  user,
  className,
  autoPayEnabled,
  showAutoPay = false,
  forAdminView = false,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  const teachersColumnHeaders = [
    "Pronouns",
    "Email",
    "Phone Number",
    "Ethnicity",
    "Racial Identity",
    "Country of Residence",
    "State",
    "Time Zone",
  ];

  const rows = [
    ["ID", user.id],
    ["Name", user.fullName],
    ["Pronouns", getUserPronounsText(user.pronouns, "--")],
    ["Email", user.email],
    ["Phone Number", user.phoneNumber ?? "--"],
    ["Ethnicity", getUserEthnicityText(user.ethnicity, "--")],
    ["Racial Identity", getUserRacialIdentityText(user.racialIdentity, "--")],
    [
      "Status",
      <AccountStatusBadge key={0} accountStatus={user.accountStatus} />,
    ],
    ["Role", <AccountRoleBadge key={0} accountRole={user.accountRole} />],
    ["Country of Residence", getCountryString(user.countryId)],
    ["State", getNAStateString(user.stateId)],
    ["Time Zone", getTimeZoneString(user.timeZone)],
    [
      "Invited On",
      <span key={0}>
        {getNormalizedShortReadableDate(new Date(user.inviteSentAt))}
      </span>,
    ],
    [
      "Joined On",
      <span key={0}>
        {getNormalizedShortReadableDate(new Date(user.createdAt))}
      </span>,
    ],
    [
      "AutoPay",
      <AutoPayBadge key={0} autoPayEnabled={autoPayEnabled ?? false} />,
    ],
  ].filter(
    (row) =>
      (row[0] !== "AutoPay" || showAutoPay) &&
      (forAdminView || teachersColumnHeaders.includes(String(row[0])))
  ) as CardDetailsRow[];

  return (
    <>
      <Card
        icon="avatar"
        rows={rows}
        className={className}
        heightMode={HeightMode.Full}
        headerOverlayColor={HeaderColors.Teal}
        header={
          forAdminView
            ? "User Details"
            : getEditModalOpenHeader("Personal Information", () =>
                setShowModal(true)
              )
        }
      />

      <EditUserPersonalInfoModal
        show={showModal && !forAdminView}
        userId={user.id}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
