import { gql } from "@apollo/client";
import { CompletedAgreementModal_CompletedAgreementFragment } from "@generated/graphql";
import { Modal } from "components/shared";
import { CompletedAgreementModalBody } from "./components/CompletedAgreementModalBody";

CompletedAgreementModal.fragments = {
  completedAgreement: gql`
    fragment CompletedAgreementModal_CompletedAgreement on CompletedAgreement {
      document {
        title
        revision
      }
      ...CompletedAgreementModalBody_CompletedAgreement
    }
    ${CompletedAgreementModalBody.fragments.completedAgreement}
  `,
};

type Props = {
  completedAgreement: CompletedAgreementModal_CompletedAgreementFragment;
  onClose(): void;
};

export function CompletedAgreementModal({
  completedAgreement,
  onClose,
}: Props) {
  const { title, revision } = completedAgreement.document;
  return (
    <Modal
      show
      title={
        <p>
          {title} <span className="italic">(rev. {revision})</span>
        </p>
      }
      icon={<Modal.Icon icon="signature" />}
      onClose={onClose}
      width="xxlarge"
      dataTest="completed-agreement-modal"
    >
      <CompletedAgreementModalBody
        completedAgreement={completedAgreement}
        onClose={onClose}
      />
    </Modal>
  );
}
