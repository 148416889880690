import { CountryCode, getAllCountries } from "countries-and-timezones";
import { useMemo } from "react";
import { SelectMenuProps } from "..";
import { SelectMenu, SelectMenuOption } from "../SelectMenu";

type CountrySelectMenuOption = SelectMenuOption<{
  countryCode: CountryCode | null;
}>;

type Props = {
  className?: string;
  initialValue: CountryCode | string | null | undefined;
  selectMenuProps?: Exclude<SelectMenuProps, "initialIndex">;
  onSelect: (countryCode: CountryCode | string | null) => void;
};

export function CountrySelectMenu({
  className,
  initialValue,
  selectMenuProps,
  onSelect,
}: Props) {
  const options = useMemo(() => {
    const allCountries = getAllCountries();
    const sortedCountries = Object.keys(allCountries).sort((a, b) => {
      if (a === "US") return -1;
      if (b === "US") return 1;
      if (a === "CA") return -1;
      if (b === "CA") return 1;
      const aName = allCountries[a as CountryCode].name;
      const bName = allCountries[b as CountryCode].name;
      return aName.localeCompare(bName);
    }) as CountryCode[];

    const options: CountrySelectMenuOption[] = [
      {
        id: "none",
        value: "--",
        selectedLabel: "None Selected",
        countryCode: null,
      },
    ];

    options.push(
      ...sortedCountries.map((countryCode) => ({
        id: countryCode,
        value: allCountries[countryCode].name,
        countryCode,
      }))
    );
    return options;
  }, []);

  const initialCountryIndex = useMemo(() => {
    return options.findIndex(({ countryCode }) => countryCode === initialValue);
  }, [initialValue, options]);

  return (
    <SelectMenu
      {...selectMenuProps}
      options={options}
      className={className}
      initialIndex={initialCountryIndex}
      onSelect={({ countryCode }) => onSelect(countryCode)}
    />
  );
}
