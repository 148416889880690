import clsx from "clsx";
import { useState } from "react";
import { itemSubHeaderStyle } from "sections/Profile";
import { Icon } from "./Icon";

export type ChecklistItemProps = {
  loading?: boolean;
  complete?: boolean;
  title: React.ReactNode;
  trackedForCompletion?: boolean;
  collapsibleContent?: React.ReactNode;
};

export const ChecklistItem = ({
  title,
  complete,
  loading = false,
  collapsibleContent,
  trackedForCompletion,
}: ChecklistItemProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const getIcon = loading ? (
    <Icon
      size={5}
      icon="loadingIcon"
      color="text-blue-700"
      className="animate-spin"
    />
  ) : !trackedForCompletion ? (
    <Icon icon="infoCircle" size={5} color="text-gray-600" />
  ) : complete ? (
    <Icon icon="checkmarkCircle" color="text-blue-700" size={5} />
  ) : (
    <Icon icon="triangleExclamation" size={5} color="text-slate-600" />
  );

  return (
    <div
      className={clsx(
        "flex flex-col rounded-[5px] border border-slate-300/70 px-[10px] py-0",
        isCollapsed ? "bg-white" : "bg-gray-100/70",
        isCollapsed && !!collapsibleContent && "hover:bg-slate-100/60"
      )}
    >
      <div
        className={clsx(
          "flex items-center w-full text-lg group py-[10px]",
          !!collapsibleContent ? "cursor-pointer" : "cursor-default"
        )}
        onClick={() => !!collapsibleContent && setIsCollapsed(!isCollapsed)}
      >
        <div className="flex items-center justify-between w-full">
          <div className={clsx("flex items-center gap-3", itemSubHeaderStyle)}>
            {getIcon}
            {title}
          </div>

          {collapsibleContent && (
            <Icon
              size={6}
              className="-my-2"
              icon={!isCollapsed ? "chevronUp" : "chevronDown"}
              color="text-blue-600 group-hover:text-emerald-600"
            />
          )}
        </div>
      </div>

      {collapsibleContent && !isCollapsed && (
        <div
          className={clsx(
            "flex flex-col py-[10px] px-5 text-sm font-medium leading-snug text-gray-700",
            "border border-gray-200 rounded-[5px] bg-white mb-[10px]"
          )}
        >
          {collapsibleContent}
        </div>
      )}
    </div>
  );
};
