import { gql } from "@apollo/client";
import { TutoringPreferencesChecklistItem_UserFragment } from "@generated/graphql";
import clsx from "clsx";
import { ChecklistItem } from "components/shared";
import { useState } from "react";
import {
  itemContentStyle,
  itemSubContentStyle,
} from "sections/Profile/constants";
import { EditTutoringPreferencesModal } from "../../TutoringPreferencesCard/components/EditTutoringPreferencesModal";

TutoringPreferencesChecklistItem.fragments = {
  user: gql`
    fragment TutoringPreferencesChecklistItem_User on User {
      id
      tutoringPreferences {
        id
        positions
        subjects {
          id
        }
        availabilities {
          timeSlotId
          daysOfWeek
        }
      }
    }
  `,
};

type Props = {
  userId: string;
  user: TutoringPreferencesChecklistItem_UserFragment | null | undefined;
  refetch: () => void;
};

export function TutoringPreferencesChecklistItem({
  userId,
  user,
  refetch,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  const hasPositions = !!user?.tutoringPreferences?.positions.length;
  const hasSubjects = !!user?.tutoringPreferences?.subjects.length;
  const hasAvailabilities = !!user?.tutoringPreferences?.availabilities?.filter(
    ({ daysOfWeek }) => daysOfWeek && daysOfWeek.length !== 0
  ).length;

  let completeCount = 0;
  let incompleteCount = 0;
  hasPositions ? ++completeCount : ++incompleteCount;
  hasSubjects ? ++completeCount : ++incompleteCount;
  hasAvailabilities ? ++completeCount : ++incompleteCount;

  const completionText =
    incompleteCount > 0
      ? ` (${Math.floor(
          (completeCount / (completeCount + incompleteCount)) * 100
        )}% complete)`
      : "";

  return (
    <>
      <ChecklistItem
        trackedForCompletion
        complete={incompleteCount === 0}
        loading={!user}
        title={
          <div className="flex items-center gap-x-1">
            Fill out your
            <span
              className={clsx(
                "underline-offset-2 decoration-gray-400/90 cursor-pointer",
                "hover:decoration-blue-400",
                incompleteCount > 0 && "underline "
              )}
              onClick={() => setShowModal(true)}
            >
              Tutoring Preferences
            </span>
            {completionText}
          </div>
        }
        collapsibleContent={
          incompleteCount > 0 ? (
            <>
              <p className={clsx(itemContentStyle, "pb-1")}>
                To complete this item, you still need to fill out the following:
              </p>
              <ul
                className={clsx(
                  "list-disc list-inside *:ml-1",
                  itemSubContentStyle
                )}
              >
                {!hasPositions && <li>Teaching positions</li>}
                {!hasSubjects && <li>Subjects and grades</li>}
                {!hasAvailabilities && <li>Availability</li>}
              </ul>
            </>
          ) : null
        }
      />

      <EditTutoringPreferencesModal
        userId={userId}
        show={showModal}
        hasPreferences={!!user?.tutoringPreferences}
        refetch={refetch}
        closeModal={() => {
          refetch();
          setShowModal(false);
        }}
      />
    </>
  );
}
