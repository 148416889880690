import {
  CohortAssignmentRole,
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
} from "@generated/graphql";
import {
  CohortAssignmentRoleTextMode,
  CohortAssignmentSubjectTextMode,
  getCohortAssignmentRoleText,
  getCohortSubjectText,
} from "@utils/labels";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = {
  cohortSubject: CohortAssignmentSubject;
  cohortAssignmentRoleOptions?: {
    cohortAssignmentRole: CohortAssignmentRole;
    mode?: CohortAssignmentRoleTextMode;
  };
  cohortSubSubject?: CohortAssignmentSubSubject;
  mode?: CohortAssignmentSubjectTextMode;
  className?: string;
};

export const CohortAssignmentSubjectBadge = ({
  cohortSubject,
  cohortSubSubject,
  cohortAssignmentRoleOptions,
  mode = "long",
  className,
}: Props) => (
  <Badge
    className={clsx(
      SubjectColors[cohortSubject],
      "flex text-center w-auto px-2 py-0.5 rounded-full whitespace-nowrap",
      className
    )}
  >
    {getCohortSubjectText(cohortSubject, mode, cohortSubSubject)}
    {cohortAssignmentRoleOptions &&
      ` (${getCohortAssignmentRoleText(
        cohortAssignmentRoleOptions.cohortAssignmentRole,
        cohortAssignmentRoleOptions.mode
      )})`}
  </Badge>
);

export const SubjectColors: { [key in CohortAssignmentSubject]: string } = {
  [CohortAssignmentSubject.Math]: "text-purple-900 bg-purple-200",
  [CohortAssignmentSubject.Ela]: "text-emerald-900 bg-emerald-200",
  [CohortAssignmentSubject.General]: "text-blue-900 bg-blue-200",
  [CohortAssignmentSubject.Science]: "text-teal-900 bg-teal-200",
  [CohortAssignmentSubject.SocialStudies]: "text-pink-900 bg-pink-200",
  [CohortAssignmentSubject.Eld]: "text-orange-900 bg-orange-200",
};
