import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { forwardRef, MouseEventHandler } from "react";
import { FieldError } from "../Error/FieldError";

type Props = {
  id: string;
  rows?: number;
  name?: string;
  label?: string;
  value?: string;
  error?: string;
  readOnly?: boolean;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  description?: string;
  defaultValue?: string;
  textAreaClassName?: string;
  leftIcon?: (props: React.ComponentProps<"svg">) => JSX.Element;
  onClick?: MouseEventHandler<HTMLTextAreaElement>;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

function TextAreaForwardRef(
  {
    id,
    name,
    label,
    value,
    error,
    rows = 5,
    className,
    description,
    placeholder,
    defaultValue,
    readOnly = false,
    required = false,
    disabled = false,
    textAreaClassName,
    leftIcon: LeftIcon,
    onBlur,
    onClick,
    onChange,
  }: Props,
  ref: React.Ref<HTMLTextAreaElement>
) {
  return (
    <div className={clsx("flex flex-col gap-1", className)}>
      {label ? (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}{" "}
          {required && (
            <span className="text-gray-800 text-sm font-semibold">*</span>
          )}
        </label>
      ) : (
        // Screen reader only
        <label htmlFor={id} className="sr-only">
          {placeholder ?? "TextArea"}
        </label>
      )}

      <div className="relative">
        {LeftIcon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <LeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        {readOnly ? (
          <div className="">{value}</div>
        ) : (
          <textarea
            id={id}
            ref={ref}
            name={name}
            rows={rows}
            value={value}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            aria-describedby={`${id}-input-description`}
            className={clsx(
              disabled && "bg-gray-50 text-gray-400 ",
              "shadow-sm block w-full h-150 sm:text-sm border-gray-300 rounded-md",
              "focus:ring-blue-500 focus:border-blue-500",
              "placeholder-gray-500 placeholder-opacity-50",
              LeftIcon && " pl-10 ",
              getScrollbarStyle("gray"),
              textAreaClassName
            )}
            onBlur={onBlur}
            onClick={onClick}
            onChange={onChange}
          />
        )}
      </div>
      {description && (
        <p className={clsx("mt-2 text-sm ")} id={`${id}-input-description`}>
          {description}
        </p>
      )}
      <FieldError msg={error} />
    </div>
  );
}

TextAreaForwardRef.displayName = "TextArea";

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  TextAreaForwardRef
);
