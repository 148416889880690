import clsx from "clsx";
import { ReactNode } from "react";

export const Details = ({
  children,
  label,
  className,
  labelClassName,
}: {
  children: ReactNode;
  label?: ReactNode;
  className?: string;
  labelClassName?: string;
}) => (
  <div className={clsx(className, "pl-4 sm:px-0")}>
    {label && (
      <h2
        className={clsx(
          labelClassName,
          "text-md leading-8 font-medium text-gray-500 border-b border-gray-500"
        )}
      >
        {label}
      </h2>
    )}
    <dl className={clsx(Boolean(label) && "pl-2 sm:px-2")}>{children}</dl>
  </div>
);

const DetailLine = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div className={clsx(className, "py-2 sm:grid sm:grid-cols-3 sm:gap-4")}>
    {children}
  </div>
);

const DescriptionTerm = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <dt
    className={clsx(
      typeof children === "string" && "text-sm font-medium text-gray-500",
      className
    )}
  >
    {children}
  </dt>
);

const DescriptionDetail = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <dd
    className={clsx(
      "mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2",
      className
    )}
  >
    {children}
  </dd>
);

const ActionDetail = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div className={clsx("flex gap-2 items-start", className)}>{children}</div>
);

export const detailLine = (
  name: string,
  value: string | ReactNode,
  className?: string,
  valueClassName?: string
) => (
  <div className={clsx(className, "py-0.5 sm:grid sm:grid-cols-5 sm:gap-3")}>
    <div className="text-sm font-semibold text-gray-700 col-span-2">{name}</div>
    <div
      className={clsx(
        "text-sm font-normal text-gray-600 col-span-3",
        valueClassName
      )}
    >
      {value}
    </div>
  </div>
);

Details.Line = DetailLine;
Details.Term = DescriptionTerm;
Details.Detail = DescriptionDetail;
Details.Action = ActionDetail;
