export type CardDetailsRow = [React.ReactNode, React.ReactNode];

export type CardSize = "sm" | "md" | "lg";

export enum HeightMode {
  Full = "h-full",
  Fit = "h-fit",
}

export enum HeaderColors {
  Violet = "bg-violet-800",
  Blue = "bg-blue-800",
  Gray = "bg-gray-800",
  Green = "bg-green-800",
  Red = "bg-red-800",
  Yellow = "bg-yellow-800",
  Indigo = "bg-indigo-800",
  Purple = "bg-purple-800",
  Pink = "bg-pink-800",
  Teal = "bg-teal-800",
  Orange = "bg-orange-800",
  Rose = "bg-rose-800",
  Cyan = "bg-cyan-800",
  Amber = "bg-amber-800",
  Lime = "bg-lime-800",
  Sky = "bg-sky-800",
  Fuchsia = "bg-fuchsia-800",
  Emerald = "bg-emerald-800",
  Black = "bg-black",
  Slate = "bg-slate-800",
}
