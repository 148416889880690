import { Popover } from "@headlessui/react";
import { resolveStringyFunction } from "@utils/strings";
import clsx from "clsx";
import { FC, PropsWithChildren, ReactNode } from "react";
import {
  OpenOrientation,
  PopoverButton,
  PopoverButtonProps,
  getPopoverPanelOrientationRules,
} from "../Buttons/PopoverButton";
import { Icon } from "../Icon";
import { ReactTooltipProps, Tooltip } from "../Tooltip";

const MODE_THEMES = {
  error: {
    icon: (className?: string) => (
      <Icon icon="remove" size={5} className={className || "text-rose-500"} />
    ),
    classNameH1: "text-red-800",
    classNameContent: "text-red-700",
    classNamePanel: "bg-red-50 border-2 border-rose-700",
  },
  warning: {
    icon: (className?: string) => (
      <Icon
        icon="exclamationCircle"
        size={5}
        className={className || "text-amber-500"}
      />
    ),
    classNameH1: "text-amber-800",
    classNameContent: "text-amber-700",
    classNamePanel: "bg-amber-50 border-2 border-amber-700",
  },
  info: {
    icon: (className?: string) => (
      <Icon icon="info" size={5} className={className || "text-blue-500"} />
    ),
    classNameH1: "text-blue-800",
    classNameContent: "text-blue-700",
    classNamePanel: "bg-blue-50 border-2 border-blue-700",
  },
};

type ErrorPopoverProps = ErrorContentProps & {
  label?: string | ((open: boolean) => string);
  labelIconClassName?: string | ((open: boolean) => string);
  labelTextClassName?: string | ((open: boolean) => string);
  openOrientation?: OpenOrientation;
  customPopoverButtonFunc?: FC<PopoverButtonProps>;
};
/**
 * Creates a PopoverButton that displays an error message when clicked.
 * * There are three modes: error (red), warning (amber), and info (blue).
 * * Provide a header string to display at the top.
 * * You can pass in an array of issues and they'll be added to an ordered list.
 * * You can also pass a customContent node to display. It will be below the
 * ordered list of issues if both are provided.
 * * OpenOrientation is the direction the Popover will open in relation to the
 * button. Defaults to "TOP".
 * * The button will have an icon and the label (if provided), or you can provide
 * a child node which will be used as the button instead.
 * * If using the default button, you can provide a labelIconClassName which will
 * be applied to the icon (provide size and color, for example).
 */
export function ErrorPopover({
  label,
  labelIconClassName = "",
  labelTextClassName = "",
  header,
  issues,
  mode,
  openOrientation = "TOP",
  customContent,
  customPopoverButtonFunc,
}: ErrorPopoverProps) {
  const { orientationClassNames } =
    getPopoverPanelOrientationRules(openOrientation);

  const defaultPopoverButtonFunc = ({ open = false }: PopoverButtonProps) => (
    <div className="flex flex-row items-center gap-1">
      {MODE_THEMES[mode].icon(resolveStringyFunction(labelIconClassName, open))}
      {label && (
        <span
          className={clsx(
            resolveStringyFunction(labelTextClassName, open) ||
              "text-md font-normal"
          )}
        >
          {resolveStringyFunction(label, open)}
        </span>
      )}
    </div>
  );

  return (
    <PopoverButton
      buttonClassName={(open) => (open ? "opacity-40" : "")}
      customPopoverButtonFunc={
        customPopoverButtonFunc || defaultPopoverButtonFunc
      }
    >
      <Popover.Panel
        className={clsx(
          orientationClassNames,
          "absolute z-10 w-screen max-w-xs transform px-4 sm:px-0"
        )}
      >
        <ErrorContent
          header={header}
          issues={issues}
          mode={mode}
          customContent={customContent}
        />
      </Popover.Panel>
    </PopoverButton>
  );
}

type ErrorTooltipProps = ErrorContentProps & {
  tooltipClassName?: string;
  tooltipIconClassName?: string;
  tooltipProps?: Exclude<ReactTooltipProps, "backgroundColor">;
};
/**
 * Creates a Tooltip that displays an error message when hovered.
 * * There are three modes: error (red), warning (amber), and info (blue).
 * * You can pass in an array of issues and they'll be added to an ordered list.
 * * You can also pass a customContent node to display. It will be below the
 * ordered list of issues if both are provided.
 * * OpenOrientation is the direction the Popover will open in relation to the
 * button. Defaults to "TOP".
 * * Typically we expect you to provide a child component for the Tooltip to
 * attach to, but you leave this out if you want to use the default Tooltip
 * icon.
 *   * If using the default icon, you can provide a tooltipIconClassName which
 *    will be applied to the icon (provide size and color, for example).
 */
export function ErrorTooltip({
  tooltipClassName,
  tooltipIconClassName = "",
  tooltipProps,
  header,
  issues,
  mode,
  customContent,
  children,
}: PropsWithChildren<ErrorTooltipProps>) {
  return (
    <Tooltip
      className={tooltipClassName}
      tooltipProps={{
        variant: "error",
        ...tooltipProps,
      }}
      content={
        <ErrorContent
          header={header}
          issues={issues}
          mode={mode}
          customContent={customContent}
        />
      }
    >
      {children || MODE_THEMES[mode].icon(tooltipIconClassName)}
    </Tooltip>
  );
}

type ErrorContentProps = {
  header: string;
  issues?: ReactNode[]; // Items will be put into an ordered list.
  mode: "error" | "warning" | "info";
  customContent?: ReactNode;
};

function ErrorContent({
  header,
  issues,
  mode,
  customContent,
}: ErrorContentProps) {
  return (
    <div
      className={clsx(
        "mb-4 p-4 overflow-hidden rounded-lg shadow-lg",
        MODE_THEMES[mode].classNamePanel
      )}
    >
      <div className="flex text-left">
        <div className="flex-shrink-0">{MODE_THEMES[mode].icon()}</div>
        <div className="ml-3">
          <h1
            className={clsx(
              "text-sm font-medium",
              MODE_THEMES[mode].classNameH1
            )}
          >
            {header}
          </h1>

          <div className={clsx("text-sm", MODE_THEMES[mode].classNameContent)}>
            {issues && issues.length > 0 && (
              <ul className="mt-2 list-inside list-disc">
                {issues.map((issue, i) => (
                  <li key={i}>{issue}</li>
                ))}
              </ul>
            )}
            {customContent}
          </div>
        </div>
      </div>
    </div>
  );
}
