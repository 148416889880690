import { countryTimeZoneOptions } from "@utils/selectMenuOptions";
import { CountryCode } from "countries-and-timezones";
import { useMemo } from "react";
import { SelectMenu, SelectMenuProps } from "../SelectMenu";

type Props = {
  className?: string;
  initialValue: string | null | undefined;
  countryCode: CountryCode | string | null | undefined;
  selectMenuProps?: Exclude<SelectMenuProps, "initialIndex">;
  onSelect: (timeZone: string | null) => void;
};

export const TimeZoneSelectMenu = ({
  className,
  countryCode,
  initialValue,
  selectMenuProps,
  onSelect,
}: Props) => {
  const options = useMemo(() => {
    return countryTimeZoneOptions(countryCode);
  }, [countryCode]);

  const initialTimeZoneIndex = useMemo(() => {
    return options.findIndex(({ timeZone }) => timeZone === initialValue);
  }, [initialValue, options]);

  return (
    <SelectMenu
      {...selectMenuProps}
      options={options}
      className={className}
      initialIndex={initialTimeZoneIndex}
      onSelect={({ timeZone }) => onSelect(timeZone)}
    />
  );
};
