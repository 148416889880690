import { UserPronouns } from "@generated/graphql";
import { userPronounsOptions } from "@utils/selectMenuOptions";
import { useMemo } from "react";
import { SelectMenu, SelectMenuProps } from "../SelectMenu";

type Props = {
  className?: string;
  initialPronouns?: UserPronouns | null;
  selectMenuProps?: Exclude<SelectMenuProps, "initialIndex">;
  onSelect: (pronouns: UserPronouns | null) => void;
};

export const PronounsSelectMenu = ({
  className,
  selectMenuProps,
  initialPronouns,
  onSelect,
}: Props) => {
  const initialIndex = useMemo(() => {
    return userPronounsOptions.findIndex(
      ({ pronouns }) => pronouns === initialPronouns
    );
  }, [initialPronouns]);

  return (
    <SelectMenu
      labelText="Pronouns"
      {...selectMenuProps}
      className={className}
      options={userPronounsOptions}
      initialIndex={initialIndex}
      onSelect={({ pronouns }) => onSelect(pronouns)}
    />
  );
};
