import { gql } from "@apollo/client";
import { InviteToSlackCheckListItem_ExternalUserAccountFragment } from "@generated/graphql";
import { ChecklistItem } from "components/shared";
import { itemContentStyle } from "sections/Profile/constants";

InviteToSlackCheckListItem.fragments = {
  slackAccount: gql`
    fragment InviteToSlackCheckListItem_ExternalUserAccount on ExternalUserAccount {
      type
      isDeleted
      externalId
      isDisabled
    }
  `,
};

type Props = {
  slackAccount: InviteToSlackCheckListItem_ExternalUserAccountFragment | null;
};

export function InviteToSlackCheckListItem({ slackAccount }: Props) {
  return (
    <ChecklistItem
      title="Join our Slack Community"
      trackedForCompletion
      complete={!!slackAccount}
      collapsibleContent={
        !!slackAccount ? null : (
          <div className={itemContentStyle}>
            Invitation sent via email from no-reply@slack.com!
          </div>
        )
      }
    />
  );
}
