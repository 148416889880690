import { gql } from "@apollo/client";
import { TutoringPreferencesCard_UserFragment } from "@generated/graphql";
import { APP_DEFAULT_TIME_ZONE } from "@utils/dateTime";
import clsx from "clsx";
import { Card } from "components/shared";
import {
  getEditModalOpenHeader,
  HeaderColors,
  HeightMode,
} from "components/shared/Card";
import { useState } from "react";
import { itemSubHeaderStyle } from "sections/Profile/constants";
import { getNoDataCallToAction } from "sections/Profile/helpers";
import { EditTutoringPreferencesModal } from "./components/EditTutoringPreferencesModal";
import { TutoringPreferencesCardBody } from "./components/TutoringPreferencesCardBody";

TutoringPreferencesCard.fragments = {
  user: gql`
    fragment TutoringPreferencesCard_User on User {
      id
      tutoringPreferences {
        id
        ...TutoringPreferencesCardBody_TutoringPreferences
      }
    }
    ${TutoringPreferencesCardBody.fragments.tutoringPreferences}
  `,
};

type Props = {
  className?: string;
  forAdmin?: boolean;
  heightMode?: HeightMode;
  user: TutoringPreferencesCard_UserFragment;
  refetch?: () => void;
};

export function TutoringPreferencesCard({
  className,
  forAdmin = false,
  heightMode = HeightMode.Fit,
  user: { id, tutoringPreferences: preferences },
  refetch,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  const header = !forAdmin
    ? getEditModalOpenHeader("Tutoring Preferences", () => setShowModal(true))
    : "Tutoring Preferences";

  return (
    <>
      <Card
        icon="settings"
        header={header}
        heightMode={heightMode}
        bodyClassName="bg-slate-50"
        className={`!h-fit ${className}`}
        headerOverlayColor={HeaderColors.Slate}
        headerClassName="border-violet-800/10"
      >
        {!!preferences ? (
          <TutoringPreferencesCardBody
            tutoringPreferences={preferences}
            clientTimeZoneOverride={
              forAdmin ? APP_DEFAULT_TIME_ZONE : undefined
            }
          />
        ) : forAdmin ? (
          <div className={clsx(itemSubHeaderStyle, "flex-center p-3")}>
            This user has not yet defined their tutoring preferences.
          </div>
        ) : (
          getNoDataCallToAction(
            () => setShowModal(true),
            `Please let us know about your teaching preferences.`
          )
        )}
      </Card>

      <EditTutoringPreferencesModal
        userId={id}
        show={showModal}
        hasPreferences={!!preferences}
        refetch={refetch}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
}
