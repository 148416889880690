import { gql } from "@apollo/client";
import { BuildTimeRangeString_TutoringPreferencesAvailabilityFragment } from "@generated/graphql";
import {
  DAY_MS,
  IANAtzName,
  ISODate,
  SECOND_MS,
  STANDARD_TIME_ZONES,
  setTimeForISODate,
} from "@utils/dateTime";
import { addSeconds } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

buildTimeRangeString.fragments = {
  tutoringPreferencesAvailability: gql`
    fragment BuildTimeRangeString_TutoringPreferencesAvailability on TutoringPreferencesAvailability {
      startTime
      endTime
      timeZone
    }
  `,
};

export function buildTimeRangeString(
  availability: BuildTimeRangeString_TutoringPreferencesAvailabilityFragment,
  isoDate: ISODate,
  timeZone: IANAtzName,
  hideLongTz: boolean
) {
  const beforeSlot = availability.startTime === 0;
  const afterSlot = availability.endTime >= DAY_MS - SECOND_MS;
  const startDateTime = setTimeForISODate(
    isoDate,
    availability.startTime,
    availability.timeZone
  );
  const endDateTime = setTimeForISODate(
    isoDate,
    availability.endTime,
    availability.timeZone
  );

  const tzString = getTzString(timeZone, hideLongTz);

  const startTimeString = formatInTimeZone(
    startDateTime,
    timeZone,
    "h:mmaaa"
  ).replace(":00", "");
  const endTimeString = formatInTimeZone(
    // Round up instances of :59 to get the next minute (ex: 11:59:59 -> 12pm).
    addSeconds(endDateTime, 1),
    timeZone,
    "h:mmaaa"
  ).replace(":00", "");

  if (beforeSlot) return `Before ${endTimeString}${tzString}`;
  if (afterSlot) return `After ${startTimeString}${tzString}`;

  return `${startTimeString}-${endTimeString}${tzString}`;
}

function getTzString(timeZone: IANAtzName, hideLongTz: boolean) {
  const [isShortTz, tzString] = STANDARD_TIME_ZONES[timeZone]
    ? [true, ` ${STANDARD_TIME_ZONES[timeZone].shortTz}`]
    : [false, ` (${timeZone})`];

  return isShortTz || !hideLongTz ? tzString : "";
}
