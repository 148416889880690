import clsx from "clsx";

type Props = {
  className: string;
  children: React.ReactNode;
};

export const Badge = ({ className, children }: Props) => (
  <span
    className={clsx(
      "flex flex-center min-h-5 text-center h-fit w-fit px-3 py-0.5",
      "rounded-full text-xs font-medium cursor-pointer",
      className
    )}
  >
    {children}
  </span>
);
