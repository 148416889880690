import { GradeLevel } from "@generated/graphql";
import { uniqBy } from "lodash";
import { assertDefined } from "./types";

type OrdinalSuffixes = {
  [locale: string]: {
    [rule in Intl.LDMLPluralRule]?: string;
  };
};

const suffixes: OrdinalSuffixes = {
  "en-US": {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
  },
};

/**
 * List of acceptable grade strings. Note that "K" is capitalized.
 */
export const validGradeLevelStrings = [
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const getGradeLevelFromString = (
  gradeLevelString?: string | null
): GradeLevel => {
  switch (capitalizeFirstLetter(gradeLevelString?.trim() ?? "")) {
    case "0":
    case "K":
      return GradeLevel.K;
    case "1":
      return GradeLevel.One;
    case "2":
      return GradeLevel.Two;
    case "3":
      return GradeLevel.Three;
    case "4":
      return GradeLevel.Four;
    case "5":
      return GradeLevel.Five;
    case "6":
      return GradeLevel.Six;
    case "7":
      return GradeLevel.Seven;
    case "8":
      return GradeLevel.Eight;
    case "9":
      return GradeLevel.Nine;
    case "10":
      return GradeLevel.Ten;
    case "11":
      return GradeLevel.Eleven;
    case "12":
      return GradeLevel.Twelve;
    default:
      return GradeLevel.Unknown;
  }
};

/**
 * Insert a number and in return you'll get a string with an ordinal.
 * Example: 1 -> "1st", 2 -> "2nd", 3 -> "3rd", 4 -> "4th", etc...
 *
 * Currently limited to just the "en-US" locale (good for all English).
 * Potential improvements later are to support additional locales.
 *
 * Adapted from MDN's own docs with some modifications.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules#using_options
 * @param count
 * @returns
 */
export function formatOrdinal(count: number) {
  const locale = "en-US";
  const pluralRules = new Intl.PluralRules(locale, { type: "ordinal" });
  const rule = pluralRules.select(count);
  const suffix = suffixes[locale]?.[rule] ?? "";
  return `${count}${suffix}`;
}

/**
 * Takes a grade string (typically 1-12 or K) and returns a nicely formatted
 * ordinal string for display purposes.
 * "1" -> "1st", "3" -> "3rd", "k"/"K" -> "k"/"K" or "k/Kindergarten"
 *
 * Maintaining capitalization of the word "Kindergarten" is a handy convenience,
 * just pass whatever grade with .toLowerCase() or .toUpperCase() to get the
 * desired result.
 *
 * @param grade string grade, typically a "1" - "12" or "K"/"k"
 * @param longK will convert a "k" into "kindergarten"; "K" to "Kindergarten"
 * @returns string
 */
export function formatGrade(grade: string | undefined | null, longK = false) {
  if (grade === undefined || grade === null) return "";
  const trimmedGrade = grade.trim();

  // If an empty string return unchanged.
  if (!trimmedGrade) return grade;

  // If a "k" or "K".
  if (trimmedGrade.toLowerCase() === "k") {
    return longK ? trimmedGrade + "indergarten" : trimmedGrade;
  }

  // Parse a number.
  const gradeNumber = Number(trimmedGrade);
  if (isNaN(gradeNumber)) {
    return trimmedGrade;
  }

  // Return the ordinal.
  return formatOrdinal(gradeNumber);
}

/**
 * NOTE: This is an older function. getGradeString() is the newer, better version.
 *
 * Example: "1,2,3" -> "1st, 2nd, 3rd"
 *
 * @param grade
 * @param longK
 * @returns
 */
export function formatArrayOfGrades(
  grade: string | undefined | null,
  longK = false
) {
  if (grade === undefined || grade === null) return "";

  const gradesArray = formatStringArray(grade);

  const formattedGrades = gradesArray?.map((unformattedGrade: string) =>
    formatGrade(unformattedGrade, longK)
  );

  return formattedGrades ? formattedGrades.join(", ") : "";
}

export function formatStringArray(
  stringToBeSplit: string | undefined | null
): string[] | null {
  if (stringToBeSplit === undefined || stringToBeSplit === null) return null;

  const stringArray = stringToBeSplit
    .trim()
    .split(/\s*,\s*/)
    .filter((notEmptyString) => notEmptyString !== "");

  if (stringArray.length === 0) {
    return null;
  }

  return stringArray;
}

/**
 * Takes an argument that may be a string or a function that returns a string
 * with any number of arguments. If it's a string, it will be returned as-is.
 * If it's a function, it will be called with the arguments passed to it.
 *
 * @param stringyFunc
 * @param args
 * @returns
 */
export function resolveStringyFunction<T extends unknown[]>(
  stringyFunc: string | ((...args: T) => string),
  ...args: T
): string {
  return typeof stringyFunc === "function" ? stringyFunc(...args) : stringyFunc;
}

/**
 * Takes a value that could be a string. If it is blank (after a trim()), it will
 * return a null value. This is useful in add and edit modals where null values
 * for nullable fields in our database are preferred over blank strings.
 */
export function nullOnBlankString(value: string | null | undefined) {
  return typeof value === "string" && value.trim() === "" ? null : value;
}

/**
 * Takes in a string of grades, removes duplicates and bad values, and returns
 * them in a sorted order with options to define a custom separator and whether
 * to format the grades as ordinals.
 *
 * Example: "1,3,2" -> "1st,2nd,3rd" (if format is true)
 * Example: "4, K" -> "K,4" (if format is false)
 * Example: "4, K" -> "Kindergarten,4" (if format is false and longK is true)
 *
 * @param grades - The list of grades to convert to a string (delimited string or array of strings).
 * @param separator - The separator to use between grade items. Defaults to ",".
 * @param format - Whether to format the grades as ordinals. Defaults to false.
 * @param longK - Whether to convert "k" to "kindergarten" and "K" to "Kindergarten".
 * @returns The string representation of the grade.
 */
export const getGradeString = (
  grades: string | string[],
  separator = ",",
  format = false,
  longK = false
): string => {
  const workingGrades = Array.isArray(grades) ? grades.join(",") : grades;
  const pattern = /[0-9Kk]+|[:;, ]+/g;
  const matches = workingGrades.match(pattern);
  if (!matches) return "";
  const parsedItems = matches
    .map((item) => {
      if (item === "K" || item === "k") return item;
      const num = parseInt(item, 10);
      return num <= 12 ? num : undefined;
    })
    .filter(assertDefined);

  return uniqBy(parsedItems, (item) => String(item).toUpperCase())
    .sort((a, b) => {
      if (a === "K" || a === "k") return -1;
      if (b === "K" || b === "k") return 1;
      return a - b;
    })
    .map((item) => (format ? formatGrade(String(item), longK) : item))
    .join(separator);
};

export const getGradesString = (grades: string[]): string =>
  getGradeString(grades.join(","), ", ");

export const stringToValKey = (str: string) => {
  // Hello there ARe lots of Animals4 => hello_there_are_lots_of_animals
  return str
    .toLowerCase() // convert to lowercase
    .replace(/\s+/g, "_") // replace spaces with underscores
    .replace(/[^a-z_]/g, ""); // remove non-alphabetic characters except underscores
};

const cacheKeySeparator = ":";

export function makeCacheKey(...keys: (string | number)[]) {
  return keys.join(cacheKeySeparator);
}

export function destructureCacheKey(cacheKey: string) {
  return cacheKey.split(cacheKeySeparator);
}

export function makeCacheID(modelName: string, ...keys: (string | number)[]) {
  return `${modelName}:{"cacheKey":"${makeCacheKey(...keys)}"}`;
}

/* eslint-disable no-useless-escape */
export function normalizePhoneNumber(phoneNumber: string): string {
  const pattern = /^[\+]?([2-9]\d{0,2})?[\d\s\(\)-]*[\d]$/;
  const normalizedNumber = phoneNumber.replace(pattern, "$1");
  return normalizedNumber;
}

export const lowerString = (str: string): string => {
  if (str.length === 0) return str;
  return str.toLowerCase();
};

export const capitalizeFirstLetter = (str: string): string => {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const replaceUnderscoresWithSpaces = (str: string): string => {
  return str.replace(/_/g, " ");
};
