import { User } from "@generated/graphql";
import { notifyBugsnag } from "@lib/bugsnag";

export class NotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "NotFoundError";
  }
}

export class CsvValidationError extends Error {
  public hint?: string;
  constructor(message: string, hint?: string) {
    super(message);
    this.name = "CsvValidationError";
    this.hint = hint;
  }
}

export enum CsvValidationErrorMessage {
  emptyData = "Invalid CSV: Dataset is empty.",
  invalidFormat = "Unable to detect format.",
  missingRequiredColumn = "One or more of the required column headers is missing.",
  missingRequiredData = "One or more rows are missing data for required columns.",
  incompleteContactInfoColumns = "Partial contact info columns detected - provide them all or provide none.",
  incompleteContactInfo = "Incomplete contact info detected.",
  rowLengthMismatch = "Row length does not match headers.",
  unexpectedParseError = "An unexpected error was encountered during CSV parsing.",
  unrecognizedInstructionLevel = "Encountered an unrecognized instruction level.",
  unrecognizedSubject = "Encountered an unrecognized subject.",
  invalidTimeFormat = "Unable to parse provided time.",
  unsupportedTimezone = "The given time zone is not supported at this time.",
  unsupportedEmailFormat = "The given email format is not supported.",
  missingTeacherName = "Unable to parse a teacher's name.",
  missingTeacherEmail = "Unable to parse a teacher's email.",
  invalidStartEndDates = "Unable to detect start/end dates.",
  missingScheduleByAssignedTeacher = "A teacher is assigned a subject that was not found in the schedule",
}

export enum CsvStudentDataValidationErrorMessage {
  someCohortsNotExist = "Some Cohorts don't exist (refreshing the page may help)",
  invalidEmailFormat = "Invalid email format",
}

export function extractErrorMsg(error: unknown, errorMsg?: string) {
  return error instanceof Error
    ? error.message
    : errorMsg ?? "An unknown error occurred. Please contact support.";
}

export function notifyError({
  error,
  componentStack,
  user,
  unhandled = false,
}: {
  error: unknown;
  componentStack?: string;
  user?: Pick<User, "id" | "email" | "fullName"> | null;
  unhandled?: boolean;
}) {
  const bugsnagUser = {
    id: user?.id,
    email: user?.email,
    fullName: user?.fullName,
  };

  notifyBugsnag({ error, componentStack, user: bugsnagUser, unhandled });
}
